import React from "react"
import {useNavigate} from "react-router-dom"
import {useDispatch} from "react-redux"
import actions from "../../redux/products/actions"

import Mesh from "../../assets/categories/mesh-fabric.webp"
import Rhinestone from "../../assets/categories/rhinestone-fabric.webp"
import Lurex from "../../assets/categories/lurex-fabric.webp"
import Lame from "../../assets/categories/lame-fabric.webp"
import Chiffon from "../../assets/categories/chiffon-fabric.webp"
import VelvetSequins from "../../assets/categories/velvet-sequins-fabric.webp"
import VinylFabric from "../../assets/categories/vinyl-fabric.webp"

function CategoriesNew() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  return (
    <section className="categories-grid pt-50">
      <div className="container">
        <div className="section-heading">
          <h2>OUR CATEGORIES</h2>
        </div>
        <div className="row">
          {/* CATEGORIES LEFT */}
          <div className="col-xl-4 col-sm-12 same-height">
            <div className="row">
              <div className="col-xl-12 col-sm-12">
                <div
                  className="category-box-grid"
                  onClick={() => {
                    setTimeout(() => {
                      dispatch(
                        actions.setParams({
                          category: {
                            _id: "638b474aa1ecae697bce7bcf",
                            name: "Mesh",
                          },
                        }),
                      )
                    }, 1500)
                    navigate(`/products/category=638b474aa1ecae697bce7bcf+Mesh`)
                  }}>
                  <img
                    src={Mesh}
                    alt="Buy Mesh from Lieve Care"
                  />
                  <div className="category-name">
                    <h5>
                      Mesh Fabric{" "}
                      <svg
                        className="right-arrow"
                        stroke="#000"
                        fill="#000"
                        stroke-width="0.5"
                        viewBox="0 0 16 16"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          fillRule="evenodd"
                          d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"></path>
                      </svg>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col-xl-12 col-sm-12 mt-3">
                <div
                  className="category-box-grid"
                  onClick={() => {
                    setTimeout(() => {
                      dispatch(
                        actions.setParams({
                          category: {
                            _id: "638b4725a1ecae697bce7bc0",
                            name: "Lace",
                          },
                        }),
                      )
                    }, 1500)
                    navigate(`/products/category=638b4725a1ecae697bce7bc0+Lace`)
                  }}>
                  <img
                    src={Rhinestone}
                    alt="Buy Lace from Lieve Care"
                  />
                  <div className="category-name">
                    <h5>
                      Lace Fabric{" "}
                      <svg
                        className="right-arrow"
                        stroke="#000"
                        fill="#000"
                        stroke-width="0.5"
                        viewBox="0 0 16 16"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          fillRule="evenodd"
                          d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"></path>
                      </svg>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col-xl-12 col-sm-12 mt-3">
                <div
                  className="category-box-grid"
                  onClick={() => {
                    setTimeout(() => {
                      dispatch(
                        actions.setParams({
                          category: {
                            _id: "638b473aa1ecae697bce7bca",
                            name: "Lurex",
                          },
                        }),
                      )
                    }, 1500)
                    navigate(
                      `/products/category=638b473aa1ecae697bce7bca+Lurex`,
                    )
                  }}>
                  <img
                    src={Lurex}
                    alt="Buy Lurex from Lieve Care"
                  />
                  <div className="category-name">
                    <h5>
                      Lurex Fabric{" "}
                      <svg
                        className="right-arrow"
                        stroke="#000"
                        fill="#000"
                        stroke-width="0.5"
                        viewBox="0 0 16 16"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          fillRule="evenodd"
                          d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"></path>
                      </svg>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* MOST SELLING CATEGORY */}
          <div className="col-xl-4 col-sm-12 same-height">
            <div className="col-xl-12 col-sm-12" style={{display: "flex"}}>
              <div
                className="category-box-grid-center"
                onClick={() => {
                  setTimeout(() => {
                    dispatch(
                      actions.setParams({
                        category: {
                          _id: "638b4758a1ecae697bce7bd9",
                          name: "Vinyl",
                        },
                      }),
                    )
                  }, 1500)
                  navigate(`/products/category=638b4758a1ecae697bce7bd9+Vinyl`)
                }}>
                <img
                  src={VinylFabric}
                  alt="Buy Vinyl from Lieve Care"
                />
                <div className="category-name">
                  <h5>
                    Vinyl Fabric{" "}
                    <svg
                      className="right-arrow"
                      stroke="#000"
                      fill="#000"
                      stroke-width="0.5"
                      viewBox="0 0 16 16"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"></path>
                    </svg>
                  </h5>
                </div>
              </div>
            </div>
          </div>

          {/* CATEGORIES RIGHT */}
          <div className="col-xl-4 col-sm-12 same-height">
            <div className="row">
              <div className="col-xl-12 col-sm-12">
                <div className="category-box-grid">
                  <img
                    src={Lame}
                    alt="Buy Lame from Lieve Care"
                  />
                  <div className="category-name">
                    <h5>
                      Lame Fabric{" "}
                      <svg
                        className="right-arrow"
                        stroke="#000"
                        fill="#000"
                        stroke-width="0.5"
                        viewBox="0 0 16 16"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          fillRule="evenodd"
                          d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"></path>
                      </svg>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col-xl-12 col-sm-12 mt-3">
                <div
                  className="category-box-grid"
                  onClick={() => {
                    setTimeout(() => {
                      dispatch(
                        actions.setParams({
                          category: {
                            _id: "638b4711a1ecae697bce7bbb",
                            name: "Chiffon",
                          },
                        }),
                      )
                    }, 1500)
                    navigate(
                      `/products/category=638b4711a1ecae697bce7bbb+Chiffon`,
                    )
                  }}>
                  <img
                    src={Chiffon}
                    alt="Buy Chiffon from Lieve Care"
                  />
                  <div className="category-name">
                    <h5>
                      Chiffon Fabric{" "}
                      <svg
                        className="right-arrow"
                        stroke="#000"
                        fill="#000"
                        stroke-width="0.5"
                        viewBox="0 0 16 16"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          fillRule="evenodd"
                          d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"></path>
                      </svg>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col-xl-12 col-sm-12 mt-3">
                <div
                  className="category-box-grid"
                  onClick={() => {
                    setTimeout(() => {
                      dispatch(
                        actions.setParams({
                          category: {
                            _id: "638b4752a1ecae697bce7bd4",
                            name: "Velvet",
                          },
                        }),
                      )
                    }, 1500)
                    navigate(
                      `/products/category=638b4752a1ecae697bce7bd4+Velvet`,
                    )
                  }}>
                  <img
                    src={VelvetSequins}
                    alt="Buy Velvet Sequins from Lieve Care"
                  />
                  <div className="category-name">
                    <h5>
                      Velvet Sequins Fabric{" "}
                      <svg
                        className="right-arrow"
                        stroke="#000"
                        fill="#000"
                        stroke-width="0.5"
                        viewBox="0 0 16 16"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          fillRule="evenodd"
                          d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"></path>
                      </svg>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CategoriesNew
