/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-useless-concat */
/* eslint-disable no-useless-computed-key */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React from "react";
import { Accordion, Button, Modal } from "react-bootstrap";
import Select from "react-select";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import {
  IMG_URL,
  MinPoints,
  PointValue,
  YOUR_GOOGLE_MAPS_API_KEY,
} from "../config";
import { useDispatch } from "react-redux";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import states from "us-state-codes";
/* SHIPPING OPTIONS */
// import shippingOptions from "../data/shipping";
import { Link, useNavigate } from "react-router-dom";
import productActions from "../redux/products/actions";

import {
  placeOrder,
  getUserProfie,
  postNewAdress,
  deleteAdress,
  editAdress,
  getShippingAddressData,
  checkPromocode,
  checkPayment,
  verifyPayment,
} from "../service/api";
import { useSnackbar } from "notistack";
import { useFormik } from "formik";
import * as yup from "yup";
import { FiTrash2 } from "react-icons/fi";
import Autocomplete from "react-google-autocomplete";
import countries from "countries-list";
import SpinnerComponent from "../components/Spinner";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import useRazorpay from "react-razorpay";

// const state = [
//   { value: "United States", label: "United States" },
//   { value: "United Kingdom", label: "United Kingdom" },
//   { value: "Afghanistan", label: "Afghanistan" },
//   { value: "India", label: "India" },
// ];
const validationSchema = yup.object().shape({
  address: yup.string().required("Please select a valid address."),
  address1: yup.string(),
  city: yup.string().required(),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  state: yup.string().required(),
  // country: yup.string().required(),
  zip: yup.number().required(),
});
const validationSchemaOfData = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().email().required(),
  company: yup.string(),
  phone: yup.number().required(),
});

function SaveButton({ children, eventKey }) {
  const closeAccordion = useAccordionButton(eventKey, () =>
    console.log("Totally Custom")
  );

  return (
    <Button className="save-btn" type="submit" onClick={closeAccordion}>
      {children}
    </Button>
  );
}

function SaveShippingButton({ children, eventKey }) {
  const closeAccordion = useAccordionButton(eventKey, () =>
    console.log("Totally Custom")
  );

  return (
    <Button
      className="save-btn"
      type="submit"
      onClick={closeAccordion}
      style={{
        backgroundColor: "#005CB9",
        borderColor: "#005CB9",
      }}
    >
      {children}
    </Button>
  );
}

function Checkout() {
  /* STATE MANAGEMENT */
  const products = useSelector((state) => state?.products);
  const [cart, setCart] = useState(products?.cart);
  const [addressModal, setAddressModal] = useState(false);
  const [thanksModal, setThanksModal] = useState(false);
  const [total, setTotal] = useState(0);
  const { loggedIn } = useSelector((state) => state.user);
  const [shippingType, setShippingType] = useState("Delivery"); //Delivery
  const [paymentMode, setPaymentMode] = useState("RAZORPAY"); //COD
  const [addressData, setAddessData] = useState(null);
  const [address1Data, setAddess1Data] = useState([]);
  console.log("address1Data: ", address1Data);
  const [personalData, setPersonalData] = useState(null); //COD
  const { enqueueSnackbar } = useSnackbar();
  const [shippingOptions, setShippingOptions] = useState([]);
  const [shippingData, setShippingData] = useState(null);
  const [error, setError] = useState([]);
  const [taxData, setTaxData] = useState(null);
  const [apiCall, setApiCall] = useState(false);
  const [isPickup, setIsPickup] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const state = [];
  Object.entries(countries?.countries).map((key) => {
    state.push({ value: key[0], label: key[1].name });
  });

  const [userData, setUserData] = useState();
  const [defaultAddress, setDefaultAddress] = useState("");
  useEffect(() => {
    getUserProfie().then((res) => {
      if (res?.status) {
        setUserData(res?.data);
        if (loggedIn) {
          console.log("default address-->", res?.data?.address[0]);
          setDefaultAddress(res?.data?.address[0]);
          formik.setFieldValue("address", res?.data?.address[0]?.address);
          formik.setFieldValue("address1", res?.data?.address[0]?.addressLine1);
          formik.setFieldValue("city", res?.data?.address[0]?.city);
          formik.setFieldValue("firstName", res?.data?.address[0]?.firstName);
          formik.setFieldValue("lastName", res?.data?.address[0]?.lastName);
          formik.setFieldValue("country", res?.data?.address[0]?.country);
          formik.setFieldValue("state", res?.data?.address[0]?.state);
          formik.setFieldValue("zip", res?.data?.address[0]?.zipCode);
          setselectAdressId(res?.data?.address[0]?._id);
        }
      }
    });
  }, []);

  useEffect(() => {
    console.log("defaultAddress?.zipCode", defaultAddress?.zipCode);
    setShippingOptions([]);
    setShippingData(null);
    setTaxData(null);
    setIsPickup(false);
    if (defaultAddress === null) {
      formik.setFieldValue("address", "");
      formik.setFieldValue("address1", "");
      formik.setFieldValue("city", "");
      formik.setFieldValue("country", "");
      formik.setFieldValue("firstName", "");
      formik.setFieldValue("lastName", "");
      formik.setFieldValue("state", "");
      formik.setFieldValue("zip", "");
    } else {
      formik.setFieldValue("address", defaultAddress?.address);
      formik.setFieldValue("address1", defaultAddress?.addressLine1);
      formik.setFieldValue("city", defaultAddress?.city);
      formik.setFieldValue("firstName", defaultAddress?.firstName);
      formik.setFieldValue("lastName", defaultAddress?.lastName);
      formik.setFieldValue("country", defaultAddress?.country);
      formik.setFieldValue("state", defaultAddress?.state);
      formik.setFieldValue("zip", defaultAddress?.zipCode);
    }
  }, [defaultAddress]);

  /* USEEFFECT */
  useEffect(() => {
    let totalPrice = 0;
    cart?.map((obj) => {
      totalPrice +=
        (obj?.product?.specialAmount
          ? obj?.product?.specialAmount
          : obj?.amount) * obj?.quantity;
    });

    if (userData?.points >= MinPoints) {
      totalPrice -= (userData?.points * PointValue).toFixed(2);
    }
    setTotal(totalPrice);
    setPayAmount(totalPrice);
  }, [cart, userData]);

  const changePayment = () => {
    let paymentM = document.getElementsByName("payment-type");

    for (let i = 0, length = paymentM?.length; i < length; i++) {
      if (paymentM[i].checked) {
        // do whatever you want with the checked radio
        setPaymentMode(paymentM[i].value);
        // only one radio can be logically checked, don't check the rest
        break;
      }
    }
  };

  //COUPON CODE
  const [couponCode, setCouponCode] = useState("");
  const [couponCodeErr, setCouponCodeErr] = useState("");
  const [couponCodeData, setCouponCodeData] = useState(null);
  const [codeSubmited, setCodeSubmited] = useState(false);
  const [payAmount, setPayAmount] = useState(0.0);

  const handleCouponCode = (checker) => {
    if (couponCode.trim().length !== 0) {
      setCouponCodeErr("");
      setCodeSubmited(true);
      let isUSA = false;
      if (
        address1Data?.filter((obj) => obj?.types?.[0] === "country")?.[0]
          ?.short_name === "US"
      ) {
        isUSA = true;
      }
      checkPromocode({
        code: couponCode,
        cart: cart,
        totalAmount: total,
        shippingData: shippingData,
        taxData: taxData,
        isUSA,
      })
        .then((res) => {
          setCodeSubmited(false);
          console.log("res: ", res);
          if (res.status) {
            setCouponCodeErr("");
            if (!checker) {
              enqueueSnackbar(res?.message, {
                variant: "success",
              });
            }
            setPayAmount(res?.data?.payAmount.toFixed(2));

            document.getElementById("couponCodeDisplayButton").click();
            setCouponCodeData(res.data);
            setCouponCode("");
          } else {
            console.log("In function error");
            setCouponCodeData(null);
            if (checker) {
              enqueueSnackbar(res?.message, {
                variant: "error",
              });
            }
            setCouponCodeErr(res?.message);
          }
        })
        .catch((err) => {
          setCodeSubmited(false);
          setCouponCodeData(null);

          console.log("err: ", err);
          if (err?.response?.data?.message) {
            console.log("Error", err?.response?.data?.message);
            if (err?.response?.data?.message?.details) {
              if (checker) {
                enqueueSnackbar(
                  err?.response?.data?.message?.details?.[0]?.message,
                  {
                    variant: "error",
                  }
                );
              }
              setCouponCodeErr(
                err?.response?.data?.message?.details?.[0]?.message
              );
            } else {
              if (checker) {
                enqueueSnackbar(err?.response?.data?.message, {
                  variant: "error",
                });
              }
              setCouponCodeErr(err?.response?.data?.message);
            }
          }
        });
    } else {
      setCouponCodeErr("Please enter a coupon code.");
    }
  };
  const CouponCodeBtn = ({ eventKey }) => {
    const decoratedOnClick = useAccordionButton(eventKey, () => {});

    return (
      <p>
        <span>Have a coupon? </span>
        <span
          className="text-black"
          id="couponCodeDisplayButton"
          onClick={decoratedOnClick}
        >
          Click here to enter your code
        </span>
      </p>
    );
  };

  const changeShipping = () => {
    let shippingT = document.getElementsByName("shipment-type");

    for (let i = 0, length = shippingT?.length; i < length; i++) {
      if (shippingT[i].checked) {
        // do whatever you want with the checked radio

        // only one radio can be logically checked, don't check the rest
        break;
      }
    }
  };

  const changeShippingUPS = (tag) => {
    if (tag === "pickup") {
      setShippingType("Pickup");
      setShippingData(null);
      return;
    }
    let shippingT = document.getElementsByName("shipment-type-ups");
    setShippingType("Delivery");
    for (let i = 0, length = shippingT?.length + 1; i < length; i++) {
      if (shippingT[i].checked) {
        // do whatever you want with the checked radio
        // if (total < 75) {
        // setShippingType(shippingT[i].value);
        setShippingData(shippingOptions[shippingT[i].value]);
        // }
        // only one radio can be logically checked, don't check the rest
        break;
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      address: "",
      address1: "",
      firstName: "",
      lastName: "",
      city: "",
      country: "India",
      zip: "",
      state: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log("values: ", values);
      values.countryCode = state.filter(
        (obj) => obj?.label === formik.values.country
      )[0]?.value;
      setAddessData(values);
    },
  });

  const formikP = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      company: "",
    },
    validationSchema: validationSchemaOfData,
    onSubmit: (values) => {
      console.log("values: ", values);
      setPersonalData(values);
      enqueueSnackbar("Your Details have been saved successfully.", {
        variant: "success",
      });
    },
  });

  const handleOrder = () => {
    let body = {
      shippingType,
      paymentMode,
    };
    console.log("formikP: ", formikP);
    console.log(
      "!formikP.isValid || !formikP.isSubmitting: ",
      !formikP.isValid || !formikP.isSubmitting
    );
    if (loggedIn) {
      let cartIdArr = cart.map((obj) => obj?._id);
      body.cartIdArr = cartIdArr;
      console.log("formik: ", formik);
    } else if (!formikP.isValid || !formikP.isSubmitting) {
      enqueueSnackbar("Please fill your valid personal data");
      return;
    } else {
      body.personalData = personalData;
      body.email = personalData?.email;
      body.cartIdArr = cart;
    }
    console.log("shippingType: ", shippingType);
    console.log("formik: ", formik);
    if (!formik.values?.address || formik.values?.address === "") {
      enqueueSnackbar("Please select the address to proceed further", {
        variant: "error",
      });
      return;
    }
    // if (!formik.values?.country || formik.values?.country === "") {
    //   enqueueSnackbar("Please select the country to proceed further", {
    //     variant: "error",
    //   })
    //   return
    // }
    if (!formik?.values?.zip || formik?.values?.zip === "") {
      enqueueSnackbar("Please enter ZIP to proceed further", {
        variant: "error",
      });
      return;
    }
    if (!formik?.values?.city || formik?.values?.city === "") {
      enqueueSnackbar("Please enter city to proceed further", {
        variant: "error",
      });
      return;
    }
    // if (!formik.isValid) {
    //   enqueueSnackbar(
    //     `Please enter ${
    //       shippingType !== "Delivery" ? "billing" : "shipping"
    //     } address`,
    //     { variant: "error" }
    //   );
    //   return;
    // } else {
    formik.values.countryCode = state.filter(
      (obj) => obj?.label === "India"
    )[0]?.value;
    formik.values.stateCode = states.getStateCodeByStateName(
      formik.values?.state
    );
    body.shippingAddress = formik.values;
    body.billingAddress = formik.values;
    // }
    let weight = 0;
    cart?.map((obj) => {
      weight = weight + obj.product?.weight;
    });
    body.weight = weight;
    // if (shippingType === "Delivery") {
    //   if (!shippingData) {
    //     enqueueSnackbar("Please select shipping option to proceed further.", {
    //       variant: "error",
    //     });
    //     return;
    //   }
    //   body.shippingData = shippingData;
    // }
    // if (userData?.points >= MinPoints) {
    //     body.pointUse = true;
    // }
    if (taxData) {
      let taxAmount =
        (taxData?.tax_rate * total.toFixed(2)) / 100
          ? (taxData?.tax_rate * total.toFixed(2)) / 100
          : null;
      if (taxAmount !== null) {
        body.taxAmount = taxAmount;
      }
    }
    if (couponCodeData && couponCodeData?.code) {
      body.promoCode = couponCodeData?.code;
    }
    console.log("%c Line:462 🍎 body", "color:#ea7e5c", body);
    setApiCall(true);
    placeOrder(body)
      .then((res) => {
        setApiCall(false);
        console.log("res: ", res);
        if (res.status && res.status === true) {
          //   if (res?.data && paymentMode === "RAZORPAY") {
          //     console.log("%c Line:461 🧀 res?.data", "color:#93c0a4", res?.data);

          //     // window.location.href = res.data;
          //   } else {
          enqueueSnackbar(res?.message, { variant: "success" });
          dispatch(productActions.setOrderConfirmation(res?.data));
          dispatch(productActions.setCartData([]));
          // navigate("/order-success")
          setShippingData(null);
          setError([]);
          setTaxData(null);
          setIsPickup(false);
          handlePayment(res);
          //   }
        } else {
          navigate("/order-cancle");
          enqueueSnackbar(res?.message, { variant: "error" });
        }
      })
      .catch((err) => {
        setApiCall(false);
        console.log("err: ", err, err.response);
        if (err?.response?.data?.message) {
          enqueueSnackbar(err?.response?.data?.message, {
            variant: "error",
          });
        }
        if (err?.response?.status === 410) {
          setError(err?.response?.data?.data);
        }
      });
  };

  const handlePayment = (id) => {
    console.log("%c Line:496 🥛 id", "color:#b03734", id);
    checkPayment(id?.data?._id)
      .then((res) => {
        console.log("res==>", res);
        if (res?.status) {
          openRazorpayCheckout(id, res);
        }
      })
      .catch((e) => {
        console.log("e==>", e);
      });
  };
  const [Razorpay] = useRazorpay();

  const openRazorpayCheckout = (id, res) => {
    try {
      console.log("%c Line:528 🍏 id", "color:#e41a6a", id);
      console.log("%c Line:528 🍞 res", "color:#42b983", res);
      const options = {
        key: "rzp_live_mjGnapT3qJQTlc",
        amount: res?.data?.amount,
        currency: res?.data?.currency,
        name: "Lieve",
        order_id: res?.data?.id,
        description: "Payment for your product/service",
        image: "test.png",
        handler: function (response) {
          console.log("%c Line:537 🍯 response", "color:#e41a6a", response);
          let data = {
            rzp_order_id: response?.razorpay_order_id,
            rzp_payment_id: response?.razorpay_payment_id,
            rzp_signature: response?.razorpay_signature,
          };
          verifyPayment(id?.data?._id, data)
            .then((res) => {
              console.log("res==>", res);
              if (res?.status) {
                navigate("/order-success");
              }
            })
            .catch((e) => {
              navigate("/order-cancle");
              console.log("e==>", e);
            });

          // alert(response.razorpay_payment_id);
          // alert(response.razorpay_order_id);
          // alert(response.razorpay_signature);
        },
        // prefill: {
        //     name: "Piyush Garg",
        //     email: "youremail@example.com",
        //     contact: "9999999999",
        // },
        // notes: {
        //     address: "Razorpay Corporate Office",
        // },
        theme: {
          color: "#3399cc",
        },
      };

      const rzp1 = new Razorpay(options);

      rzp1.on("payment.failed", function (response) {
        console.log("%c Line:562 🧀 response", "color:#7f2b82", response);
        // alert(response.error.code);
        // alert(response.error.description);
        // alert(response.error.source);
        // alert(response.error.step);
        // alert(response.error.reason);
        // alert(response.error.metadata.order_id);
        // alert(response.error.metadata.payment_id);
      });

      rzp1.open();

      // const rzp = new window.Razorpay(options);
      // rzp.open();
    } catch (error) {
      console.log("%c Line:531 🍐 error", "color:#93c0a4", error);
    }
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  const calculateFinalTotal = () => {
    console.log("shippingData", shippingData);
    console.log("total", total);
    console.log("taxData?.tax_rate", taxData?.tax_rate);

    // shippingData && total.toFixed(2) && taxData?.tax_rate
    //     ? shippingData?.freeShippingOption && total.toFixed(2) > 75
    //         ? setPayAmount(
    //               (total + (total * taxData?.tax_rate) / 100).toFixed(2),
    //           )
    //         : setPayAmount(
    //               (
    //                   shippingData?.shipmentCost +
    //                   total +
    //                   (total * taxData?.tax_rate) / 100
    //               ).toFixed(2),
    //           )
    //     : setPayAmount(total.toFixed(2));

    // shippingData &&
    //   (total.toFixed(2) < 75 ||
    //     (total.toFixed(2) > 75 &&
    //       !shippingData?.freeShippingOption))
    //   ? setPayAmount((
    //     shippingData?.shipmentCost +
    //     total +
    //     (shippingData?.shipmentCost *
    //       taxData?.tax_rate) /
    //     100
    //   ).toFixed(2))
    //   : shippingData &&
    //     taxData ? setPayAmount((
    //       total +
    //       (shippingData?.shipmentCost *
    //         taxData?.tax_rate) /
    //       100
    //     ).toFixed(2)) : setPayAmount(total.toFixed(2))

    if (couponCode.trim().length !== 0) {
      handleCouponCode(true);
    }
  };
  const [modalState, setModalState] = useState();
  const [addressId, setAddressId] = useState();
  const [input, setInput] = useState({
    address: "",
    firstName: "",
    lastName: "",
    addressLine1: null,
    city: "",
    state: "",
    zipCode: "",
    country: "",
  });
  const [selectAdressId, setselectAdressId] = useState(0);

  const [inputError, setInputError] = useState({
    address: false,
    addressLine1: false,
    city: false,
    state: false,
    zipCode: false,
    country: false,
    firstName: false,
    lastName: false,
  });

  useEffect(() => {
    calculateFinalTotal();
  }, [shippingData, total]);

  const [loading, setLoading] = useState(false);

  const addAdress = (e) => {
    e.preventDefault();

    console.log("input", input);
    if (!input?.address) {
      setInputError({ ...inputError, address: true });
      return;
    }
    // if (!input?.addressLine1) {
    //   setInputError({ ...inputError, addressLine1: true });
    //   return;
    // }
    if (!input?.city) {
      setInputError({ ...inputError, city: true });
      return;
    }
    if (!input?.firstName) {
      setInputError({ ...inputError, firstName: true });
      return;
    }
    if (!input?.lastName) {
      setInputError({ ...inputError, lastName: true });
      return;
    }
    if (!input?.state) {
      setInputError({ ...inputError, state: true });
      return;
    }
    if (!input?.zipCode) {
      setInputError({ ...inputError, zipCode: true });
      return;
    }
    if (!input?.country) {
      setInputError({ ...inputError, country: true });
      return;
    }
    if (input?.addressLine1 === "" || input?.addressLine1 === null) {
      delete input?.addressLine1;
    }
    console.log(input.addressLine1);
    setAddressModal(false);
    setLoading(true);
    if (modalState === "Add") {
      postNewAdress(input)
        .then((res) => {
          console.log(res);
          if (res?.status)
            enqueueSnackbar(res?.message, { variant: "success" });

          getUserProfie().then((res) => {
            setUserData(res?.data);
            console.log("res?.data?.address[0]", res?.data?.address[0]);
            setLoading(false);
            setDefaultAddress(res?.data?.address[0]);
            formik.setFieldValue("address", res?.data?.address[0]?.address);
            formik.setFieldValue(
              "address1",
              res?.data?.address[0]?.addressLine1
            );
            formik.setFieldValue("city", res?.data?.address[0]?.city);
            formik.setFieldValue("firstName", res?.data?.address[0]?.firstName);
            formik.setFieldValue("lastName", res?.data?.address[0]?.lastName);
            formik.setFieldValue("country", res?.data?.address[0]?.country);
            formik.setFieldValue("state", res?.data?.address[0]?.state);
            formik.setFieldValue("zip", res?.data?.address[0]?.zipCode);
            setselectAdressId(res?.data?.address[0]?._id);
            console.log(res?.data);
          });
          setInput({
            ...input,
            address: "",
            lastName: "",
            firstName: "",
            addressLine1: "",
            city: "",
            state: "",
            zipCode: "",
            country: "",
          });
        })
        .catch((e) => {
          enqueueSnackbar(e?.message, { variant: "error" });
          setLoading(false);
        });
    } else if (modalState === "Edit") {
      editAdress(addressId, input)
        .then((res) => {
          console.log(res);
          if (res?.status)
            enqueueSnackbar(res?.message, { variant: "success" });
          setAddressModal(false);
          getUserProfie().then((res) => {
            setUserData(res?.data);
            setLoading(false);
            setDefaultAddress(res?.data?.address[0]);
            formik.setFieldValue("address", res?.data?.address[0]?.address);
            formik.setFieldValue(
              "address1",
              res?.data?.address[0]?.addressLine1
            );
            formik.setFieldValue("city", res?.data?.address[0]?.city);
            formik.setFieldValue("country", res?.data?.address[0]?.country);
            formik.setFieldValue("firstName", res?.data?.address[0]?.firstName);
            formik.setFieldValue("lastName", res?.data?.address[0]?.lastName);
            formik.setFieldValue("state", res?.data?.address[0]?.state);
            formik.setFieldValue("zip", res?.data?.address[0]?.zipCode);
            setselectAdressId(res?.data?.address[0]?._id);
            console.log(res?.data);
          });
          setInput({
            ...input,
            address: "",
            addressLine1: "",
            city: "",
            state: "",
            zipCode: " ",
            lastName: "",
            firstName: "",
            country: "",
          });
        })
        .catch((e) => {
          enqueueSnackbar(e?.message, { variant: "error" });
          setLoading(false);
        });
    }
  };

  const deleteAdressFun = (id) => {
    console.log(id);
    setLoading(true);
    deleteAdress(id)
      .then((res) => {
        console.log(res);
        if (res?.status) enqueueSnackbar(res?.message, { variant: "success" });
        getUserProfie().then((res) => {
          setUserData(res?.data);
          setLoading(false);
          setDefaultAddress(res?.data?.address[0]);
          formik.setFieldValue("address", res?.data?.address[0]?.address);
          formik.setFieldValue("address1", res?.data?.address[0]?.addressLine1);
          formik.setFieldValue("city", res?.data?.address[0]?.city);
          formik.setFieldValue("firstName", res?.data?.address[0]?.firstName);
          formik.setFieldValue("lastName", res?.data?.address[0]?.lastName);
          formik.setFieldValue("country", res?.data?.address[0]?.country);
          formik.setFieldValue("state", res?.data?.address[0]?.state);
          formik.setFieldValue("zip", res?.data?.address[0]?.zipCode);
          setselectAdressId(res?.data?.address[0]?._id);
          console.log("id--->", res?.data?.address[0]?._id);
        });
      })
      .cathc((e) => {
        enqueueSnackbar(e?.message, { variant: "error" });
      });
  };

  const deleteItem = (index) => {
    setCart(cart.filter((obj) => obj?._id !== index));
    setError(
      error?.filter(
        (obj) =>
          obj?.product !== cart[index].product?._id &&
          obj?.color !== cart[index]?.color?._id
      )
    );
    console.log("cart: ", cart);
  };

  const [show, setShow] = useState(false);
  const [callApi, setCallapi] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState();

  const CalulateShippingCustomBtn = ({ children, eventKey }) => {
    const closeAccordion = useAccordionButton(eventKey, () =>
      console.log("Totally Custom")
    );
    const handleCalculateShipping = () => {
      console.log(formik);

      if (!formik.values?.address || formik.values?.address === "") {
        enqueueSnackbar("Please select the address to proceed further", {
          variant: "error",
        });
        return;
      }
      // if (!formik.values?.country || formik.values?.country === "") {
      //   enqueueSnackbar("Please select the country to proceed further", {
      //     variant: "error",
      //   })
      //   return
      // }
      if (!formik?.values?.zip || formik?.values?.zip === "") {
        enqueueSnackbar("Please enter ZIP to proceed further", {
          variant: "error",
        });
        return;
      }
      if (!formik?.values?.address || formik?.values?.address === "") {
        enqueueSnackbar("Please enter address to proceed further", {
          variant: "error",
        });
        return;
      }
      if (!formik?.values?.city || formik?.values?.city === "") {
        enqueueSnackbar("Please enter city to proceed further", {
          variant: "error",
        });
        return;
      }
      let stateName = states.getStateCodeByStateName(formik.values?.state)
        ? states.getStateCodeByStateName(formik.values?.state)
        : formik.values?.state;
      // address1Data?.map((obj) => {
      //   if (obj?.types?.includes("administrative_area_level_1")) {
      //     stateName = obj?.short_name;
      //   }
      // });
      let weight = 0;
      cart?.map((obj) => {
        weight = weight + obj?.quantity * obj.product?.weight;
      });
      console.log("weight: ", weight);

      setCallapi(true);
      getShippingAddressData({
        country: state.filter((obj) => obj?.label === "India")[0]?.value,
        zipCode: formik.values?.zip.toString(),
        weight,
        city: formik.values?.city,
        state: stateName,
        address: formik.values?.address,
      })
        ?.then((res) => {
          setCallapi(false);

          console.log("res: ", res);
          if (res?.status) {
            closeAccordion();
            setShippingOptions(res?.data?.shippingData);
            setIsPickup(res?.data?.calculateDistance?.status);
            setTaxData(res?.data?.taxData);
          }
        })
        .catch((err) => {
          setCallapi(false);

          console.log("err: ", err);
        });
    };
    return (
      <Button
        className="save-btn"
        onClick={() => {
          handleCalculateShipping();
        }}
        type="submit"
      >
        {children}
      </Button>
    );
  };

  return (
    <div className="checkout">
      {/* CHECKOUT */}
      <section className="details-section pt-50 pb-50">
        <div className="container">
          <div className="row">
            {/* CUSTOMER DETAILS */}
            <div className="col-lg-7 col-sm-12">
              {/* SAVED ADDRESS */}
              {loggedIn && (
                <div className="saved-addresses">
                  <div className="row">
                    <div
                      className="d-flex align-items-center"
                      style={{
                        justifyContent: "space-between",
                      }}
                    >
                      <h5>Your Saved Addresses</h5>
                      <Button
                        className="add-new-address-btn"
                        onClick={() => {
                          setAddressModal(true);
                          setModalState("Add");
                        }}
                      >
                        Add New Address
                      </Button>
                    </div>

                    {!loading ? (
                      userData?.address?.map((data, i) => {
                        return (
                          <div className="col-xl-4 col-sm-12">
                            <div
                              // className="address-box selected"
                              className={`address-box  ${
                                selectAdressId === data?._id ||
                                selectAdressId === i
                                  ? "selected"
                                  : ""
                              }`}
                              onClick={() => {
                                if (data?._id === selectAdressId) {
                                  setselectAdressId(null);
                                  setDefaultAddress(null);
                                  return;
                                }
                                setselectAdressId(data?._id);
                                setDefaultAddress(data);
                                setTaxData(null);
                                setShippingOptions(null);
                              }}
                            >
                              <div className="address-details">
                                <p>
                                  {data?.address &&
                                    data?.address?.split(",")[0]}
                                </p>
                                {data?.addressLine1 && (
                                  <p>{data?.addressLine1}</p>
                                )}
                                <div className="address">
                                  <p>
                                    {data?.firstName}, {data?.lastName}
                                  </p>
                                  <p>{data?.city}</p>
                                  <p>
                                    {data?.state}, {data?.zipCode}
                                  </p>
                                  <p>{data?.country}</p>
                                </div>
                                <div className="actions">
                                  <Button
                                    className="edit-btn"
                                    onClick={() => {
                                      setAddressModal(true);
                                      setModalState("Edit");
                                      setAddressId(data?._id);
                                      setInput({
                                        ...input,
                                        address: data?.address,
                                        addressLine1: data?.addressLine1,
                                        city: data?.city,
                                        firstName: data?.firstName,
                                        lastName: data?.lastName,
                                        state: data?.state,
                                        zipCode: data?.zipCode,
                                        country: data?.country,
                                      });
                                    }}
                                  >
                                    Edit
                                  </Button>
                                  <Button
                                    className="delete-btn"
                                    onClick={() => {
                                      deleteAdressFun(data?._id);
                                    }}
                                  >
                                    Delete
                                  </Button>
                                </div>
                              </div>
                              <div className="selected-icon">
                                <img
                                  src={require("../assets/icons/check.png")}
                                  width="25"
                                  alt="Address Selected"
                                />
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "12vh",
                        }}
                      >
                        <SpinnerComponent />
                      </div>
                    )}
                  </div>
                </div>
              )}

              <Modal
                size="lg"
                show={addressModal}
                onHide={() => setAddressModal(!addressModal)}
              >
                <Modal.Header closeButton>
                  <Modal.Title className="address-modal-title">
                    {modalState} Address
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="address-modal-body">
                  <form>
                    <div className="row">
                      <div className="col-xl-12 col-sm-12">
                        <div className="form-group mb-2">
                          <label htmlFor="address">Address</label>
                          {/* <Autocomplete
                            className="form-control"
                            apiKey={YOUR_GOOGLE_MAPS_API_KEY}
                            // defaultValue={input?.address}
                            inputAutocompleteValue={input?.address}
                            options={{
                              types: ["address"],
                            }}
                            onChange={(e) => {
                              console.log("e: ", e, e.target.value);
                              setInput({
                                ...input,
                                address: e.target.value,
                              });
                              setInputError({
                                ...inputError,
                                address: false,
                              });
                            }}
                            onPlaceSelected={(place) => {
                              console.log(place);
                              // input.address = place?.formatted_address;
                              console.log("address", place?.formatted_address);
                              // setInputError({ ...inputError, address: false });
                              // setInput({ ...input, ['address']: place?.formatted_address })
                              let address = "";

                              place?.address_components?.map((data) => {
                                if (data?.types?.includes("street_number")) {
                                  address = address + data?.long_name + " ";
                                }
                                if (data?.types?.includes("route")) {
                                  address = address + data?.long_name + " ";
                                }
                                input.address = address;
                                setInputError({
                                  ...inputError,
                                  address: false,
                                });

                                if (data?.types?.includes("postal_code")) {
                                  setInput({
                                    ...input,
                                    zipCode: data?.long_name,
                                  });
                                  setInputError({
                                    ...inputError,
                                    zipCode: false,
                                  });
                                  input.zipCode = data?.long_name;
                                }
                                if (data?.types?.includes("country")) {
                                  setInput({
                                    ...input,
                                    country: data?.long_name,
                                  });
                                  setInputError({
                                    ...inputError,
                                    country: false,
                                  });
                                  input.country = data?.long_name;

                                  console.log("country", data?.long_name);
                                }
                                if (
                                  data?.types?.includes(
                                    "administrative_area_level_1"
                                  )
                                ) {
                                  setInput({
                                    ...input,
                                    state: data?.long_name,
                                  });
                                  setInputError({
                                    ...inputError,
                                    state: false,
                                  });
                                  input.state = data?.long_name;
                                  console.log("state", data?.long_name);
                                }
                                if (data?.types?.includes("locality")) {
                                  setInputError({
                                    ...inputError,
                                    city: false,
                                  });
                                  input.city = data?.long_name;
                                }
                                // if (data?.types?.includes("locality")) {
                                //   setInputError({
                                //     ...inputError,
                                //     addressLine1: false,
                                //   });
                                //   input.addressLine1 = data?.long_name;
                                // }
                              });
                            }}
                          /> */}
                          {/* <small
                            className="validation-error"
                            style={{ color: "red" }}
                          > */}
                            {/* {inputError?.address
                              ? "address line 1 is required"
                              : ""} */}
                          {/* </small> */}
                        </div>
                      </div>
                      <div className="col-xl-6 col-sm-12 mb-3">
                        <div className="form-group">
                          <input
                            type="text"
                            id="city"
                            name="firstName"
                            value={input?.firstName}
                            className="form-control"
                            placeholder="First Name"
                            onChange={(e) => {
                              setInputError({
                                ...inputError,
                                [e.target.name]: false,
                              });
                              setInput({
                                ...input,
                                [e.target.name]: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <small
                          className="validation-error"
                          style={{ color: "red" }}
                        >
                          {inputError?.firstName ? "FirstName is required" : ""}
                        </small>
                      </div>
                      <div className="col-xl-6 col-sm-12 mb-3">
                        <div className="form-group">
                          <input
                            type="text"
                            id="city"
                            name="lastName"
                            value={input?.lastName}
                            className="form-control"
                            placeholder="Last Name"
                            onChange={(e) => {
                              setInputError({
                                ...inputError,
                                [e.target.name]: false,
                              });
                              setInput({
                                ...input,
                                [e.target.name]: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <small
                          className="validation-error"
                          style={{ color: "red" }}
                        >
                          {inputError?.lastName ? "LastName is required" : ""}
                        </small>
                      </div>
                      <div className="col-xl-12 col-sm-12">
                        <div className="form-group mb-2">
                          <input
                            type="text"
                            id="address"
                            name="address"
                            value={input?.address}
                            className="form-control"
                            placeholder="Address Line 1"
                            onChange={(e) => {
                              setInputError({
                                ...inputError,
                                [e.target.name]: false,
                              });
                              setInput({
                                ...input,
                                [e.target.name]: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <small
                          className="validation-error"
                          style={{ color: "red" }}
                        >
                          {inputError?.address
                            ? "address line 1 is required"
                            : ""}
                        </small>
                      </div>
                      <div className="col-xl-12 col-sm-12">
                        <div className="form-group mb-2">
                          <input
                            type="text"
                            id="addressLine1"
                            name="addressLine1"
                            value={input?.addressLine1}
                            className="form-control"
                            placeholder="Address Line 2"
                            onChange={(e) => {
                              setInputError({
                                ...inputError,
                                [e.target.name]: false,
                              });
                              setInput({
                                ...input,
                                [e.target.name]: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <small
                          className="validation-error"
                          style={{ color: "red" }}
                        >
                          {inputError?.addressLine1
                            ? "address line 2 is required"
                            : ""}
                        </small>
                      </div>

                      <div className="col-xl-4 col-sm-12">
                        <div className="form-group">
                          <input
                            type="text"
                            id="city"
                            name="city"
                            value={input?.city}
                            className="form-control"
                            placeholder="City"
                            onChange={(e) => {
                              setInputError({
                                ...inputError,
                                [e.target.name]: false,
                              });
                              setInput({
                                ...input,
                                [e.target.name]: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <small
                          className="validation-error"
                          style={{ color: "red" }}
                        >
                          {inputError?.city ? "city is required" : ""}
                        </small>
                      </div>
                      <div className="col-xl-4 col-sm-12">
                        <div className="form-group">
                          <input
                            type="text"
                            id="state"
                            name="state"
                            value={input?.state}
                            className="form-control"
                            placeholder="State"
                            onChange={(e) => {
                              setInputError({
                                ...inputError,
                                [e.target.name]: false,
                              });
                              setInput({
                                ...input,
                                [e.target.name]: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <small
                          className="validation-error"
                          style={{ color: "red" }}
                        >
                          {inputError?.state ? "state is required" : ""}
                        </small>
                      </div>
                      <div className="col-xl-4 col-sm-12">
                        <div className="form-group">
                          <input
                            type="text"
                            id="zipCode"
                            name="zipCode"
                            value={input?.zipCode}
                            className="form-control"
                            placeholder="Pin code"
                            onChange={(e) => {
                              setInputError({
                                ...inputError,
                                [e.target.name]: false,
                              });
                              setInput({
                                ...input,
                                [e.target.name]: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <small
                          className="validation-error"
                          style={{ color: "red" }}
                        >
                          {inputError?.zipCode ? "zipCode is required" : ""}
                        </small>
                      </div>
                      <div className="col-xl-6 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="country">Country</label>
                          {console.log(
                            "state.filter((obj) => obj?.label === input?.country )[0]?.value: ",
                            input?.country,
                            state.filter(
                              (obj) => obj?.label === input?.country
                            )[0]?.value
                          )}
                          <Select
                            value={{
                              label: input?.country,
                              value: state.filter(
                                (obj) => obj?.label === input?.country
                              )[0]?.value,
                            }}
                            className="state-selection"
                            classNamePrefix="states"
                            options={state}
                            name="country"
                            placeholder="Select Country"
                            onChange={(e) => {
                              setInputError({
                                ...inputError,
                                ["country"]: false,
                              });
                              setInput({
                                ...input,
                                country: e.label,
                              });
                            }}
                          />
                        </div>
                        <small
                          className="validation-error"
                          style={{ color: "red" }}
                        >
                          {inputError?.country ? "country is required" : ""}
                        </small>
                      </div>
                    </div>
                  </form>
                </Modal.Body>
                <Modal.Footer>
                  <Button className="add-address-btn" onClick={addAdress}>
                    Save Address
                  </Button>{" "}
                  {/* IF USED FOR CREATING NEW ADDRESS, BUTTON TEXT WILL BE ADD ADDRESS */}
                </Modal.Footer>
              </Modal>

              <Accordion className="details-accordion mt-3">
                {/* PERSONAL INFORMATION */}
                {!loggedIn && (
                  <Accordion.Item eventKey="0" className="mt-3">
                    <Accordion.Header>Personal Details</Accordion.Header>
                    <Accordion.Body className="details-accordion-body">
                      <form onSubmit={formikP.handleSubmit}>
                        <div className="row">
                          <div className="col-xl-6 col-sm-12 mb-3">
                            <div className="form-group">
                              <input
                                type="text"
                                name="firstName"
                                className="form-control"
                                placeholder="First Name *"
                                value={formikP.values.firstName}
                                onChange={formikP.handleChange}
                              />
                              <small
                                className="validation-error"
                                style={{
                                  color: "red",
                                }}
                              >
                                {formikP.errors.firstName ===
                                "firstName is a required field"
                                  ? "First Name is required"
                                  : ""}
                              </small>
                            </div>
                          </div>
                          <div className="col-xl-6 col-sm-12 mb-3">
                            <div className="form-group">
                              <input
                                type="text"
                                name="lastName"
                                className="form-control"
                                placeholder="Last Name *"
                                value={formikP.values.lastName}
                                onChange={formikP.handleChange}
                              />
                              <small
                                className="validation-error"
                                style={{
                                  color: "red",
                                }}
                              >
                                {formikP.errors.lastName ===
                                "lastName is a required field"
                                  ? "Last Name is required"
                                  : ""}
                              </small>
                            </div>
                          </div>
                          <div className="col-xl-6 col-sm-12 mb-3">
                            <div className="form-group">
                              <input
                                type="tel"
                                name="phone"
                                className="form-control"
                                placeholder="Phone *"
                                value={formikP.values.phone}
                                onChange={formikP.handleChange}
                              />
                              <small
                                className="validation-error"
                                style={{
                                  color: "red",
                                }}
                              >
                                {formikP.errors.phone ===
                                "phone is a required field"
                                  ? "Phone is required"
                                  : formikP.errors.phone}
                              </small>
                            </div>
                            {/* <div className="form-group">
                              <PhoneInput
                                placeholder="Phone"
                                defaultCountry="US"
                                value={phoneNumber}
                                onChange={setPhoneNumber}
                                className="phone-number-select"
                              />
                            </div> */}
                          </div>
                          <div className="col-xl-6 col-sm-12 mb-3">
                            <div className="form-group">
                              <input
                                type="text"
                                name="company"
                                className="form-control"
                                placeholder="Company Name (Optional)"
                                value={formikP.values.company}
                                onChange={formikP.handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-xl-12 col-sm-12">
                            <div className="form-group">
                              <input
                                type="email"
                                name="email"
                                className="form-control"
                                placeholder="Email address *"
                                value={formikP.values.email}
                                onChange={formikP.handleChange}
                              />
                              <small
                                className="validation-error"
                                style={{
                                  color: "red",
                                }}
                              >
                                {formikP.errors.email ===
                                "email is a required field"
                                  ? "Email is required"
                                  : ""}
                              </small>
                            </div>
                          </div>
                          <div className="col-xl-12 col-sm-12 mt-3">
                            <SaveButton eventKey="1">
                              Save and Continue
                            </SaveButton>
                          </div>
                        </div>
                      </form>
                    </Accordion.Body>
                  </Accordion.Item>
                )}

                {/* SHIPPING ADDRESS */}
                <Accordion.Item eventKey="1" className="mt-3">
                  <Accordion.Header>
                    {shippingType !== "Delivery" ? "Billing" : "Shipping"}{" "}
                    Address
                  </Accordion.Header>
                  <Accordion.Body className="details-accordion-body">
                    <form onSubmit={formik.handleSubmit}>
                      <div className="row">
                        <div className="col-xl-6 col-sm-12 mb-3">
                          <div className="form-group">
                            <input
                              type="text"
                              name="firstName"
                              className="form-control"
                              placeholder="First Name"
                              value={formik.values.firstName}
                              onChange={formik.handleChange}
                            />
                            {/* {console.log("formik: ", formik)} */}
                            {formik.isSubmitting && (
                              <small
                                style={{
                                  color: "red",
                                }}
                              >
                                {formik.errors.firstName}
                              </small>
                            )}
                          </div>
                        </div>
                        <div className="col-xl-6 col-sm-12 mb-3">
                          <div className="form-group">
                            <input
                              type="text"
                              name="lastName"
                              className="form-control"
                              placeholder="Last Name"
                              value={formik.values.lastName}
                              onChange={formik.handleChange}
                            />
                            {formik.isSubmitting && (
                              <small
                                style={{
                                  color: "red",
                                }}
                              >
                                {formik.errors.lastName}
                              </small>
                            )}
                          </div>
                        </div>
                        <div className="col-xl-12 col-sm-12 mb-3">
                          <div className="form-group">
                            <Autocomplete
                              className="form-control"
                              apiKey={YOUR_GOOGLE_MAPS_API_KEY}
                              // value={formik.values.address}
                              placeholder="Address Line 1"
                              onChange={(e) => {
                                // console.log("e: ", e, e.target.value);
                                formik.setFieldValue("address", e.target.value);
                                setShippingOptions([]);
                                setShippingData(null);
                                setTaxData(null);
                                setIsPickup(false);
                              }}
                              options={{
                                types: ["address"],
                              }}
                              onPlaceSelected={(place) => {
                                setAddess1Data(place?.address_components);
                                console.log(place);
                                // formik.setFieldValue(
                                //   "address",
                                //   place?.formatted_address
                                // );
                                let address = "";

                                place?.address_components?.map((data) => {
                                  if (data?.types?.includes("street_number")) {
                                    address = address + data?.long_name + " ";
                                  }
                                  if (data?.types?.includes("route")) {
                                    address = address + data?.long_name + " ";
                                  }
                                  formik.setFieldValue("address", address);
                                  if (data?.types?.includes("postal_code")) {
                                    formik.setFieldValue(
                                      "zip",
                                      data?.long_name
                                    );
                                  }
                                  if (data?.types?.includes("country")) {
                                    formik.setFieldValue(
                                      "country",
                                      data?.long_name
                                    );
                                  }
                                  if (
                                    data?.types?.includes(
                                      "administrative_area_level_1"
                                    )
                                  ) {
                                    formik.setFieldValue(
                                      "state",
                                      data?.long_name
                                    );
                                  }
                                  if (data?.types?.includes("locality")) {
                                    formik.setFieldValue(
                                      "city",
                                      data?.long_name
                                    );
                                  }
                                  // if (data?.types?.includes("locality")) {
                                  //   formik.setFieldValue(
                                  //     "address1",
                                  //     data?.long_name
                                  //   );
                                  // }
                                });
                              }}
                              value={formik.values.address}
                            />
                            {/* <small style={{ color: "red" }}>
                                {formik.errors.address}
                              </small> */}
                          </div>
                        </div>
                          {/* <div className="form-group">
                            <input
                              type="text"
                              name="address"
                              className="form-control"
                              placeholder="Address Line 1"
                              value={formik.values.address}
                              onChange={formik.handleChange}
                            />
                          </div> */}
                       
                        <div className="col-xl-12 col-sm-12 mb-3">
                          <div className="form-group">
                            <input
                              type="text"
                              name="address1"
                              className="form-control"
                              placeholder="Address Line 2"
                              value={formik.values.address1}
                              onChange={formik.handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-xl-6 col-sm-12 mb-3">
                          <div className="form-group">
                            <input
                              type="text"
                              name="city"
                              className="form-control"
                              placeholder="City/Town"
                              value={formik.values.city}
                              onChange={formik.handleChange}
                            />
                            {/* {console.log("formik: ", formik)} */}
                            {formik.isSubmitting && (
                              <small
                                style={{
                                  color: "red",
                                }}
                              >
                                {formik.errors.city}
                              </small>
                            )}
                          </div>
                        </div>
                        <div className="col-xl-6 col-sm-12 mb-3">
                          <div className="form-group">
                            <input
                              type="text"
                              name="state"
                              className="form-control"
                              placeholder="State "
                              value={formik.values.state}
                              onChange={formik.handleChange}
                            />
                            {formik.isSubmitting && (
                              <small
                                style={{
                                  color: "red",
                                }}
                              >
                                {formik.errors.state}
                              </small>
                            )}
                          </div>
                        </div>
                        <div className="col-xl-6 col-sm-12">
                          <div className="form-group">
                            <input
                              type="text"
                              name="zip"
                              className="form-control"
                              placeholder="Pin code"
                              value={formik.values.zip}
                              onChange={formik.handleChange}
                            />
                            {formik.isSubmitting && (
                              <small
                                style={{
                                  color: "red",
                                }}
                              >
                                {formik.errors.zip}
                              </small>
                            )}
                          </div>
                        </div>
                        <div className="col-xl-6 col-sm-12">
                          <Select
                            className="state-selection"
                            classNamePrefix="states"
                            placeholder="Country"
                            // options={state}
                            value={{
                              label: "India",
                              value: state.filter(
                                (obj) => obj?.label === formik.values.country
                              )[0]?.value,
                            }}
                            readOnly
                            // onChange={(e) => {
                            //   console.log("e: ", e)
                            //   formik.setFieldValue("country", "India")
                            // }}
                          />
                          {formik.isSubmitting && (
                            <small
                              style={{
                                color: "red",
                              }}
                            >
                              {formik.errors.country}
                            </small>
                          )}
                        </div>

                        <div className="col-xl-12 col-sm-12">
                          <div className="row mt-3">
                            <div className="col-xl-12 col-sm-12">
                              {/* {shippingType === "Delivery" && ( */}
                              <CalulateShippingCustomBtn eventKey="2">
                                {!callApi ? "Calculate Shipping" : "Loading..."}
                              </CalulateShippingCustomBtn>
                              {/* <Button
                                className="save-btn"
                                onClick={handleCalculateShipping}
                                type="submit"
                              >
                                Calculate Shipping
                              </Button> */}
                              {/* )} */}
                            </div>
                            {/* <div className="col-xl-6 col-sm-12">
                              <SaveShippingButton eventKey="2">
                                Save and Continue
                              </SaveShippingButton>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </form>
                  </Accordion.Body>
                </Accordion.Item>

                {/* SHIPPING METHODS */}
                {/* <Accordion.Item eventKey='2' className='mt-3'>
                  <Accordion.Header>Shipping Options</Accordion.Header>
                  <Accordion.Body className='details-accordion-body'>
                    <div className='shipping-options-list'>
                      {shippingOptions?.length > 0 ? (
                        shippingOptions?.map((option, i) => {
                          return (
                            <div className='shipping-option' key={i}>
                              <label for={"ups-ground" + i}>
                                <div className='d-flex align-items-center'>
                                  <input
                                    type='radio'
                                    name='shipment-type-ups'
                                    className='radio-control'
                                    value={i}
                                    id={"ups-ground" + (i + 1)}
                                    onChange={changeShippingUPS}
                                  />
                                  <div className='option-detail'>
                                    <p className='mb-0'>
                                      {option?.serviceName}
                                    </p>
                                  </div>
                                </div>

                                <p>
                                  $
                                  {total.toFixed(2) < 75 ||
                                  (total.toFixed(2) > 75 &&
                                    !option?.freeShippingOption)
                                    ? option?.shipmentCost.toFixed(2)
                                    : "0.00"}
                                </p>
                              </label>
                            </div>
                          );
                        })
                      ) : (
                        <div
                          style={{
                            fontFamily: "Mulish, sans-serif",
                            marginBottom: "10px",
                          }}>
                          Please click on the Calculate shipping button in the
                          shipping address accordion to view the shipping
                          options.
                        </div>
                      )}
                      {console.log(
                        "formik?.values?.city?.trim()?.toLowerCase(): ",
                        formik?.values?.city?.trim()?.toLowerCase(),
                      )}
                      {isPickup && (
                        <div className='shipping-option'>
                          <label htmlFor='store-pickup'>
                            <div className='d-flex align-items-center'>
                              <input
                                type='radio'
                                name='shipment-type-ups'
                                onChange={() => changeShippingUPS("pickup")}
                                value='Pickup'
                                checked={shippingType === "Pickup"}
                                className='radio-control'
                                id={"ups-ground" + "0"}
                              />
                              <div className='option-detail'>
                                <p className='mb-0'>
                                  <a
                                    href='https://goo.gl/maps/dLimtEnWG3DoCWxs9'
                                    target='_blank'
                                    rel='noreferrer'
                                    style={{
                                      textDecoration: "none",
                                      fontWeight: "700",
                                    }}>
                                    Store Pickup
                                  </a>
                                </p>
                                <p className='mb-0'>Next Business Day</p>
                              </div>
                            </div>
                            <p>$0.00</p>
                          </label>
                        </div>
                      )}
                      <div className='col-xl-12 col-sm-12 mt-3'>
                        <SaveButton eventKey='3'>Save and Continue</SaveButton>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item> */}

                {/* PAYMENT DETAILS */}
                <Accordion.Item eventKey="3" className="mt-3">
                  <Accordion.Header>Payment Details</Accordion.Header>
                  <Accordion.Body>
                    {/* STRIPE PAYMENT FORMS WILL COME HERE */}
                    <div className="row">
                      <div className="col-xl-12 col-sm-12">
                        <label htmlFor="RAZORPAY-option">
                          <div className="d-flex align-items-center">
                            <input
                              type="radio"
                              name="payment-type"
                              className="radio-control"
                              id="RAZORPAY-option"
                              value="RAZORPAY"
                              checked={paymentMode === "RAZORPAY"}
                              onChange={changePayment}
                            />
                            <div className="option-detail">
                              <p className="mb-0">
                                Pay with{" "}
                                <img
                                  style={{
                                    height: "20px",
                                  }}
                                  src={require("../assets/icons/razorpay.png")}
                                  alt="Razorpay Logo"
                                />
                              </p>
                            </div>
                          </div>
                        </label>
                      </div>
                      {/* {shippingType === "Pickup" && (
                        <div className="col-xl-12 col-sm-12 mt-2">
                          <label htmlFor="delivery-option">
                            <div className="d-flex align-items-center">
                              <input
                                type="radio"
                                name="payment-type"
                                className="radio-control"
                                id="delivery-option"
                                value="COD"
                                checked={paymentMode === "COD"}
                                onChange={changePayment}
                              />
                              <div className="option-detail">
                                <p className="mb-0">Cash on Delivery</p>
                              </div>
                            </div>
                          </label>
                        </div>
                      )} */}
                    </div>
                    {/* <Button
                      to="/order-placed"
                      style={{ textDecoration: "none", color: "#FFF" }}
                    > */}
                    <Button
                      onClick={handleOrder}
                      disabled={apiCall}
                      className="place-order-btn"
                    >
                      Place Order
                    </Button>
                    {/* </Button> */}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>

            {/* ORDER SUMMARY */}
            <div className="col-lg-5 col-sm-12">
              <div className="order-review">
                <div className="mb-20">
                  <h4>Your Order</h4>
                </div>
                <div
                  className="table-responsive text-center"
                  style={{
                    borderBottom: "0px solid transparent",
                  }}
                >
                  <table className="table">
                    <thead>
                      <tr>
                        <th colSpan="2">Product</th>
                        <th>Total</th>
                        {error?.length > 0 && <th>Actions</th>}
                      </tr>
                    </thead>
                    <tbody>
                      {cart &&
                        cart?.length > 0 &&
                        cart?.map((product, i) => {
                          return (
                            <>
                              <tr>
                                <td className="image product-thumbnail">
                                  <img
                                    src={
                                      IMG_URL + product.product?.productImage[0]
                                    }
                                    alt={product?.product?.name}
                                    width={80}
                                  />
                                </td>
                                <td>
                                  <h5>{product?.product?.name}</h5>
                                  <p>Quantity: {product?.quantity}</p>
                                </td>
                                <td>
                                  <p>
                                    ₹{" "}
                                    {(
                                      (product?.product?.specialAmount
                                        ? product?.product?.specialAmount
                                        : product?.amount) * product?.quantity
                                    ).toFixed(2)}
                                  </p>
                                </td>
                                {error?.length > 0 &&
                                product.product?._id ===
                                  error?.filter(
                                    (obj) =>
                                      obj?.product === product.product?._id &&
                                      obj?.color === product?.color?._id
                                  )[0]?.product ? (
                                  <td className="price delete-icon">
                                    <FiTrash2
                                      onClick={() => deleteItem(product?._id)}
                                    />
                                  </td>
                                ) : error?.length > 0 ? (
                                  <td></td>
                                ) : null}
                              </tr>
                            </>
                          );
                        })}

                      <tr>
                        <td>
                          <p className="font-weight-700">Subtotal</p>
                        </td>
                        <td colSpan="3">
                          <p>₹ {total.toFixed(2)}</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p className="font-weight-700">Shipping</p>
                        </td>
                        <td colSpan="3">
                          <p>
                            ₹{" "}
                            {shippingData &&
                            (total.toFixed(2) < 75 ||
                              (total.toFixed(2) > 75 &&
                                !shippingData?.freeShippingOption))
                              ? shippingData?.shipmentCost?.toFixed(2)
                              : "0.00"}
                            {total.toFixed(2) > 75 &&
                              shippingData?.freeShippingOption && (
                                <p>
                                  <b>{"Free Shipping"}</b>
                                </p>
                              )}
                          </p>
                        </td>
                      </tr>
                      {/* <tr>
                        <td>
                          <p className="font-weight-700">Offer Applied</p>
                        </td>
                        <td colSpan="3">
                          <p>No offer applied</p>
                        </td>
                      </tr> */}
                      <tr>
                        <td>
                          <p className="font-weight-700">Estimated Tax</p>
                        </td>
                        <td colSpan="3">
                          <p>
                            ₹{" "}
                            {shippingData && taxData
                              ? ((total * taxData?.tax_rate) / 100).toFixed(2)
                              : "0.00"}
                          </p>
                        </td>
                      </tr>
                      {userData?.points >= MinPoints ? (
                        <tr>
                          <td>
                            <p className="font-weight-700">
                              Reward Coin ({userData?.points} Points)
                            </p>
                          </td>
                          <td colSpan="3">
                            <p>
                              {userData?.points ? (
                                <>
                                  <p>
                                    {" "}
                                    <strong>
                                      {(userData?.points * PointValue).toFixed(
                                        2
                                      )}
                                    </strong>{" "}
                                    OFF
                                  </p>
                                </>
                              ) : (
                                ""
                              )}
                            </p>
                          </td>
                        </tr>
                      ) : null}
                      {couponCodeData ? (
                        <tr>
                          <td>
                            <p className="font-weight-700">Coupon Code </p>
                          </td>
                          <td colSpan="3">
                            <p>
                              {couponCodeData ? (
                                <>
                                  <h5>
                                    "{" "}
                                    <strong>
                                      {couponCodeData?.PromoCodeDetails?.code}
                                    </strong>{" "}
                                    " Applied
                                  </h5>
                                  <p>
                                    ({" "}
                                    <strong>
                                      {
                                        couponCodeData?.PromoCodeDetails
                                          ?.discountInRate
                                      }
                                      %
                                    </strong>{" "}
                                    OFF )
                                  </p>
                                </>
                              ) : (
                                ""
                              )}
                            </p>
                          </td>
                        </tr>
                      ) : null}

                      <tr>
                        <td>
                          <p className="font-weight-700 final-text">Total</p>
                        </td>
                        <td colSpan="3">
                          <p className="font-weight-700 final-text">
                            ₹ {payAmount}
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {/* <div>
                                    <Accordion className="coupon-code-accordion">
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header className="coupon-code-accordion-header">
                                                <CouponCodeBtn eventKey="0" />
                                            </Accordion.Header>
                                            <Accordion.Body className="coupon-code-accordion-body">
                                                <p>
                                                    If you have coupon code,
                                                    please apply it below.
                                                </p>
                                              
                                                <input
                                                    type="text"
                                                    value={couponCode}
                                                    onChange={(e) => {
                                                        setCouponCode(
                                                            e.target.value,
                                                        );
                                                    }}
                                                    className="form-control"
                                                    name="couponCode"
                                                    placeholder="Enter Coupon Code..."
                                                />
                                                {couponCodeErr !== "" && (
                                                    <small
                                                        className="validation-error "
                                                        style={{
                                                            color: "red",
                                                            marginBottom:
                                                                "10px",
                                                            display: "block",
                                                        }}
                                                    >
                                                        {couponCodeErr}
                                                    </small>
                                                )}

                                                <Button
                                                    disabled={codeSubmited}
                                                    className="coupon-code-btn mt-2"
                                                    onClick={() =>
                                                        handleCouponCode(false)
                                                    }
                                                >
                                                    Apply Coupon
                                                </Button>
                                                
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Checkout;
