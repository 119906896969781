import React from "react";
import FreeShipping from "../../assets/icons/free-shipping.webp";
import CardPayments from "../../assets/icons/card-payments.webp";
import EasyReturns from "../../assets/icons/easy-returns.webp";

function AboutUs() {
    return (
        <div className="about-us pt-50 pb-50">
            <div className="container">
                <div className="col-xl-12 col-sm-12">
                    <div className="logo-img pb-30">
                        <img
                            src={require("../../assets/logos/logo.png")}
                            width="150"
                            alt="Lieve Care"
                        />
                    </div>
                    <h1>Welcome to LIEVE!</h1>
                    <p className="mt-3">
                        At LIEVE, we're committed to providing you with a
                        comprehensive range of personal care products that cater
                        to every aspect of your daily routine. From indulgent
                        body washes to gentle baby products, we've curated a
                        selection of essentials designed to elevate your
                        self-care experience.
                    </p>
                    <h5>Our Journey:</h5>
                    <p>
                        LIEVE was born out of a passion for quality,
                        convenience, and wellness. We understand the importance
                        of feeling confident in the products you use every day,
                        which is why we've made it our mission to deliver
                        premium, reliable solutions directly to your doorstep.
                    </p>
                    <h5>Our Value:</h5>

                    <ol>
                        <li>
                            <b>Quality Assurance</b>: Every product in the LIEVE
                            range undergoes rigorous testing and quality checks
                            to ensure it meets our high standards. We believe in
                            offering nothing but the best to our customers.
                        </li>
                        <li>
                            <b>Inclusivity</b>: At LIEVE, we celebrate diversity
                            and believe that everyone deserves access to
                            top-notch personal care products. Our range is
                            designed to cater to all ages, skin types, and
                            preferences.
                        </li>
                        <li>
                            <b>Sustainability</b>: We're committed to reducing
                            our environmental footprint by using eco-friendly
                            packaging materials and sourcing ingredients
                            responsibly. We believe in preserving the planet for
                            future generations.
                        </li>
                        <li>
                            <b>Customer Satisfaction</b>: Your satisfaction is
                            our top priority. We're here to listen to your
                            feedback, address your concerns, and continuously
                            improve our offerings to better serve your needs.
                        </li>
                    </ol>
                    <h4>Join the LIEVE Family:</h4>

                    <p>
                        Experience the difference with LIEVE and discover a new
                        standard of personal care. Shop our range today and
                        embark on a journey towards healthier, happier skin.
                    </p>
                </div>
            </div>

            {/* FEATURES */}
            <section className="feature-area pt-50">
                <div className="container">
                    <div className="feature-wrapper">
                        <div className="single-feature-col">
                            <div className="single-feature">
                                <div className="feature-icon">
                                    <img
                                        src={CardPayments}
                                        alt="Free Shipping"
                                    />
                                </div>
                                <div className="feature-content">
                                    <h4 className="title">Free Shipping</h4>
                                    {/* <span className="sub-title">
                                        Free Domestic Shipping on purchase
                                        <br />
                                        of $75+.
                                    </span> */}
                                </div>
                            </div>
                        </div>
                        <div className="single-feature-col">
                            <div className="single-feature">
                                <div className="feature-icon">
                                    <img
                                        src={FreeShipping}
                                        alt="Card Payments"
                                    />
                                </div>
                                <div className="feature-content">
                                    <h4 className="title">Secure Payments</h4>
                                    {/* <span className="sub-title">
                                        Your payment information is processed
                                        securely.
                                    </span> */}
                                </div>
                            </div>
                        </div>
                        <div className="single-feature-col">
                            <div className="single-feature">
                                <div className="feature-icon">
                                    <img src={EasyReturns} alt="Easy Returns" />
                                </div>
                                <div className="feature-content">
                                    <h4 className="title">No Returns</h4>
                                    {/* <span className="sub-title">
                                        Responsive customer service via email,
                                        text or phone.
                                    </span> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default AboutUs;
