import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import { FaTimes } from "react-icons/fa";
import { Rating } from "react-simple-star-rating";
import { postReviews } from "../service/api";

const RatingDialog = (props) => {
  const [rating, setRating] = useState(0);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [ratingError, setRatingError] = useState(false);
  const [messageError, setMessageError] = useState(false);
  const [titleError, setTitleError] = useState(false);

  const handleClose = () => {
    props.setOpen(false);
    setRating(0);
    setTitle("");
    setMessage("");
    setRatingError(false);
    setMessageError(false);
    setTitleError(false);
  };

  const handleRating = (rate) => {
    setRating(rate);
    setRatingError(false);
  };

  const handleSubmit = () => {
    if (rating === 0) {
      setRatingError(true);
    }
    if (title?.trim() === "") {
      setTitleError(true);
    }
    if (message?.trim() === "") {
      setMessageError(true);
    }
    if (rating === 0 || title?.trim() === "" || message?.trim() === "") {
      return;
    }

    let body = {
      product: props.id,
      rating: rating.toString(),
      title: title,
      desc: message,
    };
    setLoading(true);
    postReviews(body)
      ?.then((res) => {
        if (res?.status) {
          handleClose();

          props.loadData();
        }
      })
      .catch((e) => {
        console.log("%c Line:45 🍿 e", "color:#4fff4B", e);
      })
      .finally(() => setLoading(false));
  };

  return (
    <div>
      <Dialog open={props.open} onClose={handleClose}>
        <DialogTitle>
          Rate Your Purchase Experience
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{ position: "absolute", right: 8, top: 8 }}
          >
            <FaTimes />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body2" align="center">
            Let us know what you think
          </Typography>
          <Box display="flex" justifyContent="center" mt={2}>
            <Rating
              onClick={handleRating}
              ratingValue={rating}
              size={40}
              label
              transition
              fillColor="gold"
              emptyColor="gray"
            />
          </Box>
          {ratingError && (
            <Typography color="error" align="center">
              Please select a rating.
            </Typography>
          )}
          <TextField
            label="Enter name"
            fullWidth
            margin="normal"
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
              setTitleError(false);
            }}
            error={titleError}
            helperText={titleError ? "Name cannot be empty." : ""}
          />
          <TextField
            label="Message"
            multiline
            rows={4}
            fullWidth
            margin="normal"
            value={message}
            onChange={(e) => {
              setMessage(e.target.value);
              setMessageError(false);
            }}
            error={messageError}
            helperText={messageError ? "Message cannot be empty." : ""}
          />
        </DialogContent>
        <DialogActions style={{ padding: "0 24px 10px 24px" }}>
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            fullWidth
            disabled={loading}
          >
            {loading ? "Submitting..." : "Submit"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default RatingDialog;
