/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, {useState, useEffect} from "react"
import {Link, useNavigate} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"

/* COMPONENT IMPORTS */
import ProductBox from "../../components/ProductBox"
import ProductWishlist from "../../components/WishlistProduct"

import {getWishList} from "../../service/api"
import productActions from "../../redux/products/actions"
import InfiniteScroll from "react-infinite-scroll-component"
import {Button} from "react-bootstrap"
import SpinnerComponent from "../../components/Spinner"

function FabricPage() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [wishList, setwishList] = useState([])
  const [totalResults, setTotalResults] = useState(null)
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(0)
  const [patternFilter, setPatternFilter] = useState([])
  const products = useSelector((state) => state.products)
  useEffect(() => {
    // if(products?.wishListTrigger === "start")
    // {
    //   setLoading(true)
    //   return
    // }

    setLoading(true)
    getWishList({
      page: page,
      sizePerPage: 120,
    }).then((res) => {
      if (res?.status) {
        setwishList(res?.data?.docs)
        setTotalResults(res?.data?.totalDocs)
        dispatch(productActions.setWishList(res?.data?.totalDocs))
        console.log(res?.data?.docs)
        setLoading(false)
      }
    })

    return () => {}
  }, [page])

  useEffect(() => {
    if (products && products?.length !== 0) {
      setPatternFilter(products?.pattern)
    }
  }, [products])
  const reload = () => {
    getWishList({
      page: page,
      sizePerPage: 12,
    }).then((res) => {
      if (res?.status) {
        setwishList(res?.data?.docs)
        setTotalResults(res?.data?.totalDocs)
        dispatch(productActions.setWishList(res?.data?.totalDocs))
        console.log(res?.data?.docs)
      }
    })
  }

  const handleProductClick = (data) => {
    console.log("data---->", data)
    dispatch(
      productActions.setSelectedProductId({
        selectedProductId: data?.product?._id,
        selectedProductColorId: data?.product?.productImage?.color?._id
          ? data?.productImage?.color?._id
          : data?.productImage?.color,
      }),
    )
    let wishlistActive = true

    dispatch(
      productActions.setWishlistData({
        wishlistActive: wishlistActive,
        productId: data?.product?._id,
        colorId: data?.color?._id,
      }),
    )
    navigate("/product/"+data?.product?.name)
  }

  return (
    <div className="fabric-page">
      {/* PRODUCTS */}
      <div className="shop-fabrics pt-50">
        <div className="container">
          <div className="row">
            <h1 className="wishlist-heading">YOUR WISHLIST</h1>
            {/* PRODUCTS LIST */}
            <InfiniteScroll
              dataLength={wishList.length}
              next={() => setPage(page + 1)}
              hasMore={wishList.length <= totalResults}
              loader={loading && <SpinnerComponent />}>
              <div className="row">
                {loading ? null : wishList?.length === 0 ? (
                  <div className="no-results-found pb-50">
                    <h5>Wishlist your favorite fabrics in just few clicks</h5>
                    <p>
                      We will keep you up to date with discounts, and exclusive
                      news and offers, via email.
                    </p>
                    <div className="row mt-3">
                      {patternFilter &&
                        patternFilter?.length !== 0 &&
                        patternFilter?.map((obj) => {
                          return (
                            <div className="col-xl-3 col-sm-12" key={obj?._id}>
                              <div
                                className="no-category-found"
                                onClick={() => {
                                  // setTimeout(() => {
                                  //   dispatch(
                                  //     productActions.setParams({
                                  //       category: {
                                  //         _id: "638b4758a1ecae697bce7bd9",
                                  //         name: "Vinyl",
                                  //       },
                                  //     }),
                                  //   )
                                  // }, 1500)
                                  navigate(
                                    `/products/&pattern=${obj?._id}+${obj?.name}`,
                                  )
                                }}>
                                <h5>{obj?.name} </h5>
                                <svg
                                  className="right-arrow"
                                  stroke="#000"
                                  fill="#000"
                                  stroke-width="0.5"
                                  viewBox="0 0 16 16"
                                  height="1em"
                                  width="1em"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    fillRule="evenodd"
                                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"></path>
                                </svg>
                              </div>
                            </div>
                          )
                        })}
                    </div>
                  </div>
                ) : (
                  wishList?.map((data) => {
                    return (
                      <div className="col-lg-2 col-sm-12 mb-25">
                        <div
                          onClick={() => handleProductClick(data)}
                          style={{textDecoration: "none", color: "#000"}}>
                          <ProductWishlist
                            key={data?._id}
                            id={data?.product?._id}
                            colorId={data?.color?._id}
                            img={data?.product?.productImage[0]}
                            name={data?.product?.name}
                            price={data?.product?.amount}
                            subDescriptions={data?.product?.subDescriptions}
                            composition={data?.product?.content}
                            wishList={data?.product}
                            reload={reload}
                          />
                        </div>
                      </div>
                    )
                  })
                )}
              </div>
            </InfiniteScroll>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FabricPage
