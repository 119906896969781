/* eslint-disable no-useless-escape */
import React, { useState } from "react";
import { postContact } from "../service/api";
import { useSnackbar } from "notistack";
import SpinnerComponent from "../components/Spinner";

function ContactUs() {
    const { enqueueSnackbar } = useSnackbar();

    const [input, setInput] = useState({
        name: "",
        email: "",
        phone: null,
        message: null,
    });
    const [inputError, setInputError] = useState({
        name: false,
        email: false,
        phone: false,
        message: false,
    });
    const [loading, setLoading] = useState(false);
    const onRegchange = (e) => {
        setInputError({ ...inputError, [e.target.name]: false });
        setInput({ ...input, [e.target.name]: e.target.value });
    };

    function emailValidation(body) {
        const regex =
            /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (regex.test(body) === false) {
            return false;
        }
        return true;
    }

    const registerForm = (e) => {
        e.preventDefault();

        if (!input?.name) {
            setInputError({ ...inputError, name: true });
            // enqueueSnackbar("Please enter your name");
            return;
        }
        if (!input?.email) {
            // enqueueSnackbar("Please enter email");
            setInputError({ ...inputError, email: true });
            return;
        }
        if (!emailValidation(input?.email)) {
            // enqueueSnackbar("Please enter valid email");
            setInputError({ ...inputError, email: true });
            return;
        }
        // if (!input?.phone) {
        //   // enqueueSnackbar("Please enter your phone number");
        //   setInputError({ ...inputError, phone: true })
        //   return
        // }
        // if (!input?.message) {
        //   // enqueueSnackbar("Please enter your phone number");
        //   setInputError({ ...inputError, message: true })
        //   return
        // }

        const submitForm = new FormData();
        submitForm.append("name", input.name);
        submitForm.append("email", input.email);
        submitForm.append("mobile", input.phone);
        submitForm.append("message", input.message);
        setLoading(true);
        postContact(submitForm)
            .then((res) => {
                console.log("res--->", res);
                enqueueSnackbar(
                    "Thank you for contacting Lieve. We will get back to you shortly.",
                    { variant: "success" },
                );
                setInput({ name: "", email: "", phone: "", message: "" });
            })
            .catch((e) => {
                console.log("error--->", e);
                enqueueSnackbar("Something went wrong. Please try it again.", {
                    variant: "error",
                });
            })
            .finally((res) => {
                setLoading(false);
            });
    };

    return (
        <div className="contact-us">
            <section
                className="pt-50 pb-50"
                style={{ backgroundColor: "cornsilk" }}
            >
                {loading ? (
                    <SpinnerComponent />
                ) : (
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-sm-12 m-auto">
                                <div className="contact-form-area">
                                    <h3>Drop Us a Line</h3>
                                    <p>
                                        Email us for any queries at{" "}
                                        <a
                                            href="mailto:info@lieve.care"
                                            style={{
                                                textDecoration: "none",
                                                color: "black",
                                            }}
                                        >
                                            info@lieve.care
                                        </a>{" "}
                                        or
                                        <br />
                                        Leave a message and we will contact you
                                        within 2 business days.
                                    </p>
                                    <form className="contact-form">
                                        <div className="row">
                                            <div className="col-lg-4 col-sm-12">
                                                <div className="input-style mb-20">
                                                    <input
                                                        value={input?.name}
                                                        onChange={onRegchange}
                                                        type="text"
                                                        className="form-control"
                                                        id="name"
                                                        name="name"
                                                        placeholder="Enter your Name"
                                                    />
                                                    <small
                                                        className="validation-error"
                                                        style={{ color: "red" }}
                                                    >
                                                        {inputError?.name
                                                            ? "Name is required"
                                                            : ""}
                                                    </small>
                                                </div>
                                            </div>
                                            {/* <div className="col-lg-6 col-sm-12">
                        <div className="input-style mb-20">
                          <input
                            type="text"
                            className="form-control"
                            id="last-name"
                            name="last_name"
                            placeholder="Last Name"
                          />
                        </div>
                      </div> */}
                                            <div className="col-lg-4 col-sm-12">
                                                <div className="input-style mb-20">
                                                    <input
                                                        value={input?.email}
                                                        onChange={onRegchange}
                                                        type="email"
                                                        className="form-control"
                                                        id="contact-email"
                                                        name="email"
                                                        placeholder="Your Email"
                                                    />
                                                    <small
                                                        className="validation-error"
                                                        style={{ color: "red" }}
                                                    >
                                                        {inputError?.email
                                                            ? "Please enter valid email"
                                                            : ""}
                                                    </small>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-12">
                                                <div className="input-style mb-20">
                                                    <input
                                                        value={input?.phone}
                                                        onChange={onRegchange}
                                                        type="number"
                                                        className="form-control"
                                                        id="phone"
                                                        name="phone"
                                                        placeholder="Your Phone"
                                                    />
                                                    <small
                                                        className="validation-error"
                                                        style={{ color: "red" }}
                                                    >
                                                        {inputError?.phone
                                                            ? "Please enter phone"
                                                            : ""}
                                                    </small>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-sm-12">
                                                <div className="input-style mb-20">
                                                    <textarea
                                                        value={input?.message}
                                                        onChange={onRegchange}
                                                        className="textarea-control"
                                                        name="message"
                                                        id="message"
                                                        placeholder="Message"
                                                    />
                                                    <small
                                                        className="validation-error"
                                                        style={{ color: "red" }}
                                                    >
                                                        {inputError?.message
                                                            ? "Message is required"
                                                            : ""}
                                                    </small>
                                                </div>
                                            </div>
                                            <button
                                                type="submit"
                                                className="submit-btn"
                                                onClick={registerForm}
                                            >
                                                Send message
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </section>
        </div>
    );
}

export default ContactUs;
