/* eslint-disable no-unreachable */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, {useState, useEffect} from "react"
import {Link, useNavigate, useHistory} from "react-router-dom"
import {Routes, Route, useParams} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"
import {Accordion, Button, Form} from "react-bootstrap"
import SpinnerComponent from "../../components/Spinner"

/* COMPONENT IMPORTS */
import ProductBox from "../../components/ProductBox"
import InfiniteScroll from "react-infinite-scroll-component"

/* API */
import {
  getAllProducts,
  getProductById,
  getColor,
  getPattern,
  getType,
  getComposition,
} from "../../service/api"

/* REDUX PRODUCT ACTION */
import productActions from "../../redux/products/actions"

function ProductList() {
  const {id} = useParams()
  const navigate = useNavigate()

  const [colorFilter, setColorFilter] = useState([])

  const [categoryFilter, setCategoryFilter] = useState([])

  const [contentFilter, setContentFilter] = useState([])

  const [patternFilter, setPatternFilter] = useState([])
  console.log('patternFilter: ', patternFilter);

  const products = useSelector((state) => state.products)
  const dispatch = useDispatch()

  const [allProducts, setAllProducts] = useState([])
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(12)
  const [loading, setLoading] = useState(true)
  const [totalResults, setTotalResults] = useState(null)

  useEffect(() => {
    console.log("products redux--->", products)
    setColorFilter(products?.color)
    setCategoryFilter(products?.type)
    setContentFilter(products?.composition)
    setPatternFilter(products?.pattern)
  }, [products])

  function removeDuplicates(arr) {
    return arr?.filter((item, index) => arr.indexOf(item) === index)
  }

  //only when parameter changes
  useEffect(() => {
    const removeDup = id?.split("&")

    if (
      colorFilter?.length > 0 &&
      categoryFilter?.length > 0 &&
      contentFilter?.length > 0 &&
      patternFilter?.length > 0
    ) {
      const finalArr = removeDuplicates(removeDup)
      finalArr?.map((data) => {
        if (data?.includes("color")) {
          colorFilterHandle(
            {
              _id: data?.split("=")[1].split("+")[0],
              name: data?.split("+")[1],
            },
            "add",
          )
        }
        if (data?.includes("category")) {
          categoryFilterHandle(
            {
              _id: data?.split("=")[1].split("+")[0],
              name: data?.split("+")[1],
            },
            "add",
          )
        }

        if (data?.includes("content")) {
          contentFilterHandle(
            {
              _id: data?.split("=")[1].split("+")[0],
              name: data?.split("+")[1],
            },
            "add",
          )
        }
        if (data?.includes("pattern")) {
          patternFilterHandle(
            {
              _id: data?.split("=")[1],
              name: data?.split("=")[1],
            },
            "add",
          )
        }
        if (data?.includes("tag")) {
          tagFilterHandle(
            {
              _id: data?.split("=")[1].split("+")[0],
              name: data?.split("+")[1],
            },
            "add",
          )
        }
      })
    } else {
      getColor().then((res) => {
        setColorFilter(res?.data)
        dispatch(productActions.setColor(res?.data))
      })
      getPattern().then((res) => {
        setPatternFilter(res?.data)
        dispatch(productActions.setPattern(res?.data))
      })
      getType().then((res) => {
        setCategoryFilter(res?.data)
        dispatch(productActions.setType(res?.data))
      })
      getComposition().then((res) => {
        setContentFilter(res?.data)
        dispatch(productActions.setComposition(res?.data))
      })
    }
  }, [products?.params])

  //only when page loads with params in id
  useEffect(() => {
    const removeDup = id?.split("&")
    const finalArr = removeDuplicates(removeDup)

    const onPageLoad = () => {
      if (
        finalArr?.length > 0 &&
        colorFilter?.length > 0 &&
        categoryFilter?.length > 0 &&
        contentFilter?.length > 0 &&
        patternFilter?.length > 0
      ) {
        //         _id: data?.split('=')[1].split('+')[0],
        //         name: data?.split('+')[1]
        console.log("final arr---->->", finalArr)

        //color filter
        const onlyColor = finalArr.filter((x) => x.split("=")[0] === "color")
        const newArr1 = colorFilter?.map((object) => {
          if (
            object?._id ===
            onlyColor
              .find((g) => g?.split("=")[1].split("+")[0] === object?._id)
              ?.split("=")[1]
              .split("+")[0]
          ) {
            return {...object, active: true}
          }
          return object
        })
        setColorFilter(newArr1)

        //category filter
        const onlyCategory = finalArr.filter(
          (x) => x.split("=")[0] === "category",
        )
        const newArr2 = categoryFilter.map((object) => {
          if (
            object?._id ===
            onlyCategory
              .find((g) => g?.split("=")[1].split("+")[0] === object?._id)
              ?.split("=")[1]
              .split("+")[0]
          ) {
            return {...object, active: true}
          }
          return object
        })
        setCategoryFilter(newArr2)

        //content filter
        const onlyContent = finalArr.filter(
          (x) => x.split("=")[0] === "content",
        )
        const newArr3 = contentFilter.map((object) => {
          if (
            object?._id ===
            onlyContent
              .find((g) => g?.split("=")[1].split("+")[0] === object?._id)
              ?.split("=")[1]
              .split("+")[0]
          ) {
            return {...object, active: true}
          }
          return object
        })
        setContentFilter(newArr3)

        //pattern filter
        const onlyPattern = finalArr.filter(
          (x) => x.split("=")[0] === "pattern",
        )
        const newArr4 = patternFilter.map((object) => {
          if (
            object?.name ===
            onlyPattern
              .find((g) => g?.split("=")[1] === object?._id)
              ?.split("=")[1]
          ) {
            return {...object, active: true}
          }
          return object
        })
        setPatternFilter(newArr4)
      }
    }

    if (document.readyState === "complete") {
      onPageLoad()
    } else {
      window.addEventListener("load", onPageLoad, false)
      // Remove the event listener when component unmounts
      return () => window.removeEventListener("load", onPageLoad)
    }
  }, [products])

  //only when id triggers to getproducts api
  useEffect(() => {
    // if(products?.wishListTrigger === "start")
    // {
    //   // window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    //   setLoading(true)
    //   return
    // }

    setLoading(true)

    const obj = {}

    if (page > 1) {
      setPage(1)
    }
    obj.page = 1
    obj.sizePerPage = pageSize

    const arr1 = []
    const arr2 = []
    const arr3 = []
    const arr4 = []
    const arr5 = []

    id?.split("&").map((data) => {
      if (data.split("=")[0] === "color") {
        arr1.push(data.split("=")[1].split("+")[0])
      }
      if (data.split("=")[0] === "category") {
        arr2.push(data.split("=")[1].split("+")[0])
      }
      if (data.split("=")[0] === "pattern") {
        arr3.push(data.split("=")[1].split("+")[0])
      }
      if (data.split("=")[0] === "content") {
        arr4.push(data.split("=")[1].split("+")[0])
      }
      if (data.split("=")[0] === "tag") {
        arr5.push(data.split("=")[1].split("+")[0])
      }
    })

    obj.color = arr1
    obj.category = arr2
    obj.pattern = arr3
    obj.content = arr4
    obj.tag = arr5

    if (products?.search) {
      obj.search = products?.search
    }

    getAllProducts(obj)
      .then((res) => {
        if (res?.status) {
          setAllProducts(res?.data[0]?.docs)
          setTotalResults(res?.data[0]?.metadata[0]?.total)
        }
        console.log("products--->", res?.data[0]?.docs)
        setLoading(false)
      })
      .catch((err) => {
        console.log("err", err)
      })
      .finally((err) => {
        setLoading(false)
      })

    return () => {
      setAllProducts([])
    }
  }, [id, products?.search])

  //only when page changes on Infinite scrolling
  useEffect(() => {
    if (page > 1) {
      setLoading(true)

      const obj = {}

      obj.page = page
      obj.sizePerPage = pageSize

      const arr1 = []
      const arr2 = []
      const arr3 = []
      const arr4 = []
      const arr5 = []

      id?.split("&").map((data) => {
        if (data.split("=")[0] === "color") {
          arr1.push(data.split("=")[1].split("+")[0])
        }
        if (data.split("=")[0] === "category") {
          arr2.push(data.split("=")[1].split("+")[0])
        }
        if (data.split("=")[0] === "pattern") {
          arr3.push(data.split("=")[1])
        }
        if (data.split("=")[0] === "content") {
          arr4.push(data.split("=")[1].split("+")[0])
        }
        if (data.split("=")[0] === "tag") {
          arr5.push(data.split("=")[1].split("+")[0])
        }
      })

      obj.color = arr1
      obj.category = arr2
      obj.pattern = arr3
      obj.content = arr4
      obj.tag = arr5

      if (products?.search) obj.search = products?.search

      getAllProducts(obj)
        .then((res) => {
          if (res?.status) {
            setAllProducts([...allProducts, ...res?.data[0]?.docs])
            setTotalResults(res?.data[0]?.metadata[0]?.total)
            console.log("products--->total pdt", res?.data[0]?.docs)
          }
          setLoading(false)
        })
        .catch((err) => {
          console.log("err", err)
        })
        .finally((err) => {
          setLoading(false)
        })
    }
  }, [page])

  useEffect(() => {
    console.log("products--->", allProducts)
  }, [allProducts])

  const tagFilterHandle = (data, extra) => {
    if (extra === "add") {
      if (id !== undefined) {
        navigate(`/products/tag=${data?._id}+${data?.name}`, {
          replace: true,
        })
      } else {
        navigate(`/products/tag=${data?._id}+${data?.name}`, {
          replace: true,
        })
      }

      const f = categoryFilter.map((object) => {
        return {...object, active: false}
      })
      setCategoryFilter(f)

      const s = contentFilter.map((object) => {
        return {...object, active: false}
      })
      setContentFilter(s)

      const x = patternFilter.map((object) => {
        return {...object, active: false}
      })
      setPatternFilter(x)

      const newArr = colorFilter?.map((object) => {
        return {...object, active: false}
      })
      setColorFilter(newArr)
    } else if (extra === "remove") {
      const newParams = id
        .split("&")
        .filter((x) => x !== `tag=${data?._id}+${data?.name}`)
        .join("&")
      navigate(`/products/${newParams}`, {replace: true})
    } else {
      if (id !== undefined) {
        navigate(`/products/${id}&tag=${data?._id}+${data?.name}`, {
          replace: true,
        })
      } else {
        navigate(`/products/tag=${data?._id}+${data?.name}`, {
          replace: true,
        })
      }
    }
  }

  const handleProductClick = (data) => {
    console.log("data--->", data)
    let wishlistActive = false
    if (data?.WishlistProduct?._id) {
      wishlistActive = true
    }

    dispatch(
      productActions.setWishlistData({
        wishlistActive: wishlistActive,
        productId: data?._id,
        colorId: data?.productImage.color,
      }),
    )
    dispatch(
      productActions.setSelectedProductId({
        selectedProductId: data?._id,
        selectedProductColorId: data?.productImage?.color,
      }),
    )
    navigate("/product/" + data?.name)
  }

  const colorFilterHandle = (data, extra) => {
    if (extra === "add") {
      if (id !== undefined) {
        navigate(`/products/color=${data?.name}+${data?.name}`, {
          replace: true,
        })
      } else {
        navigate(`/products/color=${data?.name}+${data?.name}`, {
          replace: true,
        })
      }

      const f = categoryFilter.map((object) => {
        return {...object, active: false}
      })
      setCategoryFilter(f)

      const s = contentFilter.map((object) => {
        return {...object, active: false}
      })
      setContentFilter(s)

      const x = patternFilter.map((object) => {
        return {...object, active: false}
      })
      setPatternFilter(x)

      const newArr = colorFilter?.map((object) => {
        if (object?._id === data?._id) {
          return {...object, active: true}
        } else {
          return {...object, active: false}
        }
        return object
      })

      setColorFilter(newArr)
    } else if (extra === "remove") {
      const newParams = id
        .split("&")
        .filter((x) => x !== `color=${data?._id}+${data?.name}`)
        .join("&")
      navigate(`/products/${newParams}`, {replace: true})

      const newArr = colorFilter?.map((object) => {
        if (object?._id === data?._id) {
          return {...object, active: false}
        }
        return object
      })
      setColorFilter(newArr)
    } else {
      if (id !== undefined) {
        navigate(`/products/${id}&color=${data?._id}+${data?.name}`, {
          replace: true,
        })
      } else {
        navigate(`/products/color=${data?._id}+${data?.name}`, {
          replace: true,
        })
      }

      const newArr = colorFilter?.map((object) => {
        if (object?._id === data?._id) {
          return {...object, active: true}
        }
        return object
      })

      setColorFilter(newArr)
    }
  }

  const categoryFilterHandle = (data, extra) => {
    if (extra === "add") {
      if (id !== undefined) {
        navigate(`/products/category=${data?._id}+${data?.name}`, {
          replace: true,
        })
      } else {
        navigate(`/products/category=${data?._id}+${data?.name}`, {
          replace: true,
        })
      }

      const f = colorFilter?.map((object) => {
        return {...object, active: false}
      })
      setColorFilter(f)

      const s = contentFilter.map((object) => {
        return {...object, active: false}
      })
      setContentFilter(s)

      const x = patternFilter.map((object) => {
        return {...object, active: false}
      })
      setPatternFilter(x)

      const newArr = categoryFilter.map((object) => {
        if (object?._id === data?._id) {
          return {...object, active: true}
        } else {
          return {...object, active: false}
        }
        return object
      })

      setCategoryFilter(newArr)
    } else if (extra === "remove") {
      const newParams = id
        .split("&")
        .filter((x) => x !== `category=${data?._id}+${data?.name}`)
        .join("&")
      navigate(`/products/${newParams}`, {replace: true})

      const newArr = categoryFilter.map((object) => {
        if (object?._id === data?._id) {
          return {...object, active: false}
        }
        return object
      })
      setCategoryFilter(newArr)
    } else {
      if (id !== undefined) {
        navigate(`/products/${id}&category=${data?._id}+${data?.name}`, {
          replace: true,
        })
      } else {
        navigate(`/products/category=${data?._id}+${data?.name}`, {
          replace: true,
        })
      }

      const newArr = categoryFilter.map((object) => {
        if (object?._id === data?._id) {
          return {...object, active: true}
        }
        return object
      })

      setCategoryFilter(newArr)
    }
  }

  const contentFilterHandle = (data, extra) => {
    if (extra === "add") {
      if (id !== undefined) {
        navigate(`/products/content=${data?._id}+${data?.name}`, {
          replace: true,
        })
      } else {
        navigate(`/products/content=${data?._id}+${data?.name}`, {
          replace: true,
        })
      }

      const f = categoryFilter.map((object) => {
        return {...object, active: false}
      })
      setCategoryFilter(f)

      const s = colorFilter?.map((object) => {
        return {...object, active: false}
      })
      setColorFilter(s)

      const x = patternFilter.map((object) => {
        return {...object, active: false}
      })
      setPatternFilter(x)

      const newArr = contentFilter.map((object) => {
        if (object?._id === data?._id) {
          return {...object, active: true}
        } else {
          return {...object, active: false}
        }
        return object
      })

      setContentFilter(newArr)
    } else if (extra === "remove") {
      const newParams = id
        .split("&")
        .filter((x) => x !== `content=${data?._id}+${data?.name}`)
        .join("&")
      navigate(`/products/${newParams}`, {replace: true})

      const newArr = contentFilter.map((object) => {
        if (object?._id === data?._id) {
          return {...object, active: false}
        }
        return object
      })
      setContentFilter(newArr)
    } else {
      if (id !== undefined) {
        navigate(`/products/${id}&content=${data?._id}+${data?.name}`, {
          replace: true,
        })
      } else {
        navigate(`/products/content=${data?._id}+${data?.name}`, {
          replace: true,
        })
      }

      const newArr = contentFilter.map((object) => {
        if (object?._id === data?._id) {
          return {...object, active: true}
        }
        return object
      })

      setContentFilter(newArr)
    }
  }

  const handleFilter = (data) => {
    if (data) {
      let arr =
        allProducts &&
        allProducts?.length !== 0 &&
        allProducts?.filter((obj) => obj?.name?.includes(data))
      setAllProducts(arr)
    } else {
      setAllProducts(allProducts)
    }
  }
  // console.log("%c Line:670 🍇 arr", "color:#42b983", arr)
  const patternFilterHandle = (data, extra) => {
    console.log("%c Line:682 🍫 data", "color:#33a5ff", data)
    console.log("%c Line:682 🍓 extra", "color:#42b983", extra)
    if (extra === "add") {
      if (id !== undefined) {
        navigate(`/products/pattern=${data?.name}`, {
          replace: true,
        })
      } else {
        navigate(`/products/pattern=${data?.name}`, {
          replace: true,
        })
      }

      const f = categoryFilter.map((object) => {
        return {...object, active: false}
      })
      setCategoryFilter(f)

      const s = contentFilter.map((object) => {
        return {...object, active: false}
      })
      setContentFilter(s)

      const x = colorFilter?.map((object) => {
        return {...object, active: false}
      })
      setColorFilter(x)

      const newArr = patternFilter.map((object) => {
        if (object?.name === data?._id) {
          return {...object, active: true}
        } else {
          return {...object, active: false}
        }
        return object
      })

      setPatternFilter(newArr)
    } else if (extra === "remove") {
      const newParams = id
        .split("&")
        .filter((x) => x !== `pattern=${data?.name}`)
        .join("&")
      navigate(`/products/${newParams}`, {replace: true})

      const newArr = patternFilter.map((object) => {
        if (object?.name === data?.name) {
          return {...object, active: false}
        }
        return object
      })
      setPatternFilter(newArr)
    } else {
      navigate(`/products/&pattern=${data?.name}`, {active: true})
      // if (id !== undefined) {
      //   navigate(`/products/${id}&pattern=${data?._id}+${data?.name}`, {
      //     replace: true,
      //   })
      // } else {
      //   navigate(`/products/pattern=${data?._id}+${data?.name}`, {
      //     replace: true,
      //   })
      // }

      // const newArr = patternFilter.map((object) => {
      //   if (object?._id === data?._id) {
      //     return {...object, active: true}
      //   }
      //   return object
      // })

      // setPatternFilter(newArr)
    }
  }

  let watch = 0

  const colorIdFilterFunction = (colorArray, productIndex) => {
    let colorArrIndex
    for (let x = 0; x < colorArray.length; x++) {
      if (watch === 0) {
        colorArrIndex = x
        watch = x + 1
        if (x === colorArray.length - 1) {
          watch = 0
        }
        break
      } else if (x === watch) {
        colorArrIndex = x
        watch = x + 1

        if (x === colorArray.length - 1) {
          watch = 0
        }
        break
      }
    }

    const resultIndex = productIndex - colorArrIndex
    const finalIndex = productIndex - resultIndex
    // console.log("productIndex", productIndex , "colorArrIndex",colorArrIndex , "finalIndex",finalIndex)
    return finalIndex
  }

  return (
    <div className="fabric-page">
      {/* PRODUCTS */}
      <div className="shop-fabrics pt-50">
        <div className="container">
          <div className="row d-flex justify-content-between">
            {/* FILTERS */}
            <div className="col-lg-3 col-sm-12 filters order-first">
              {/* COLORS */}
              {/* <Accordion className="colors-filter-accordion mb-4">
                                <Accordion.Item
                                    eventKey="0"
                                    className="colors-filter-accordion-item"
                                >
                                    <Accordion.Header className="colors-filter-accordion-header">
                                        Color
                                    </Accordion.Header>
                                    <Accordion.Body className="colors-filter-accordion-body">
                                        <div className="sidebar-widget">
                                            <div className="sidebar-category">
                                                <ul>
                                                    {colorFilter?.map(
                                                        (data) => {
                                                            return (
                                                                <li
                                                                    className={
                                                                        `category-list ${
                                                                            data?.active
                                                                                ? "active"
                                                                                : null
                                                                        } `
                                                                        // + color.selected
                                                                    }
                                                                    key={
                                                                        data?._id
                                                                    }
                                                                    onClick={() => {
                                                                        if (
                                                                            data?.active
                                                                        ) {
                                                                            colorFilterHandle(
                                                                                data,
                                                                                "remove",
                                                                            );
                                                                        } else {
                                                                            colorFilterHandle(
                                                                                data,
                                                                                null,
                                                                            );
                                                                        }
                                                                    }}
                                                                >
                                                                    <div className="d-flex align-items-center">
                                                                        <div
                                                                            className="color-category"
                                                                            style={{
                                                                                backgroundColor:
                                                                                    data.code,
                                                                            }}
                                                                        />
                                                                        <p className="color-name">
                                                                            {
                                                                                data?.name
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                </li>
                                                            );
                                                        },
                                                    )}
                                                </ul>
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion> */}

              {/* <div className="sidebar-widget">
              <h4 className="sidebar-title">Color</h4>
              <div className="sidebar-category">
                <ul>
                  {colorFilter?.map((data) => {
                    return (
                      <li
                        className={
                          `category-list ${data?.active ? "active" : null} `
                          // + color.selected
                        }
                        key={data?._id}
                        onClick={() => {
                          if (data?.active) {
                            colorFilterHandle(data, "remove");
                          } else {
                            colorFilterHandle(data, null);
                          }
                        }}
                      >
                        <div className="d-flex align-items-center">
                          <div
                            className="color-category"
                            style={{ backgroundColor: data.code }}
                          />
                          <p className="color-name">{data?.name}</p>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div> */}

              {/* FABRIC TYPE */}
              {/* <div className="sidebar-widget">
                <h4 className="sidebar-title">Fabric Type</h4>
                <div className="sidebar-category">
                  <ul>
                    {categoryFilter
                      ?.sort(function (a, b) {
                        if (a.name.toLowerCase() < b.name.toLowerCase())
                          return -1
                        if (a.name.toLowerCase() > b.name.toLowerCase())
                          return 1
                        return 0
                      })
                      .map((data) => {
                        return (
                          <li
                            className={
                              `category-list ${data?.active ? "active" : null} `
                              // + color.selected
                            }
                            key={data?._id}
                            onClick={() => {
                              if (data?.active) {
                                categoryFilterHandle(data, "remove")
                              } else {
                                categoryFilterHandle(data, "add")
                              }
                            }}>
                            <div className="d-flex align-items-center">
                              <p className="color-name ml-0">{data?.name}</p>
                            </div>
                          </li>
                        )
                      })}
                  </ul>
                </div>
              </div> */}

              {/* <Accordion className="colors-filter-accordion mb-4">
                <Accordion.Item
                  eventKey="0"
                  className="colors-filter-accordion-item"
                >
                  <Accordion.Header className="colors-filter-accordion-header">
                    Fabric Type
                  </Accordion.Header>
                  <Accordion.Body className="colors-filter-accordion-body">
                    <div className="sidebar-widget">
                    
                      <div className="sidebar-category">
                        <ul>
                          {categoryFilter
                            ?.sort(function (a, b) {
                              if (a.name.toLowerCase() < b.name.toLowerCase())
                                return -1;
                              if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                              return 0;
                            })
                            .map((data) => {
                              return (
                                <li
                                  className={
                                    `category-list ${data?.active ? "active" : null} `
                                    // + color.selected
                                  }
                                  key={data?._id}
                                  onClick={() => {
                                    if (data?.active) {
                                      categoryFilterHandle(data, "remove");
                                    } else {
                                      categoryFilterHandle(data, null);
                                    }
                                  }}
                                >
                                  <div className="d-flex align-items-center">
                                    <p className="color-name ml-0">{data?.name}</p>
                                  </div>
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion> */}

              {/* PATTERN */}
              <div className="sidebar-widget">
                <h4 className="sidebar-title">Products</h4>
                <div className="sidebar-category">
                  <ul>
                    {patternFilter?.map((data) => {
                      return (
                        <li
                          className={
                            `category-list ${data?.active ? "active" : null} `
                            // + color.selected
                          }
                          key={data?._id}
                          onClick={() => {
                            if (data?.active) {
                              patternFilterHandle(data, "remove")
                            } else {
                              patternFilterHandle(data, null)
                            }
                          }}>
                          <div className="d-flex align-items-center">
                            <p className="color-name ml-0">{data?.name}</p>
                          </div>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </div>

              {/* <Accordion className="colors-filter-accordion mb-4">
                <Accordion.Item
                  eventKey="0"
                  className="colors-filter-accordion-item"
                >
                  <Accordion.Header className="colors-filter-accordion-header">
                    Pattern
                  </Accordion.Header>
                  <Accordion.Body className="colors-filter-accordion-body">
                    <div className="sidebar-widget">
                  
                      <div className="sidebar-category">
                        <ul>
                          {patternFilter?.map((data) => {
                            return (
                              <li
                                className={
                                  `category-list ${data?.active ? "active" : null} `
                                  // + color.selected
                                }
                                key={data?._id}
                                onClick={() => {
                                  if (data?.active) {
                                    patternFilterHandle(data, "remove");
                                  } else {
                                    patternFilterHandle(data, null);
                                  }
                                }}
                              >
                                <div className="d-flex align-items-center">
                                  <p className="color-name ml-0">{data?.name}</p>
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion> */}

              {/* COMPOSITION */}
              {/* <div className="sidebar-widget">
                <h4 className="sidebar-title">Composition</h4>
                <div className="sidebar-category">
                  <ul>
                    {contentFilter?.map((data) => {
                      return (
                        <li
                          className={
                            `category-list ${data?.active ? "active" : null} `
                            // + color.selected
                          }
                          key={data?._id}
                          onClick={() => {
                            if (data?.active) {
                              contentFilterHandle(data, "remove")
                            } else {
                              contentFilterHandle(data, null)
                            }
                          }}>
                          <div className="d-flex align-items-center">
                            <p className="color-name ml-0">{data?.name}</p>
                          </div>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </div> */}

              {/* <Accordion className="colors-filter-accordion mb-4">
                <Accordion.Item
                  eventKey="0"
                  className="colors-filter-accordion-item"
                >
                  <Accordion.Header className="colors-filter-accordion-header">
                  Composition
                  </Accordion.Header>
                  <Accordion.Body className="colors-filter-accordion-body">
                  <div className="sidebar-widget">
               
                <div className="sidebar-category">
                  <ul>
                    {contentFilter?.map((data) => {
                      return (
                        <li
                          className={
                            `category-list ${data?.active ? "active" : null} `
                            // + color.selected
                          }
                          key={data?._id}
                          onClick={() => {
                            if (data?.active) {
                              contentFilterHandle(data, "remove");
                            } else {
                              contentFilterHandle(data, null);
                            }
                          }}
                        >
                          <div className="d-flex align-items-center">
                            <p className="color-name ml-0">{data?.name}</p>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion> */}
            </div>

            {/* PRODUCTS LIST */}
            <div className="col-lg-9 col-sm-12 products-list  order-md-last">
              <div className="row">
                <div
                  className={
                    id?.length > 0
                      ? "selected-filters mb-3"
                      : "selected-filters"
                  }>
                  {id?.length > 0 ? <p className="mb-0">Filters:</p> : false}
                  <div className="filters-list">
                    {id?.split("&")?.map((data) => {
                      if(data){
                        return (
                          <div className="filter-box">
                            <p>{data?.split("=")[1]}</p>
                            <Button
                              className="close-button"
                              onClick={() => {
                                if (data.split("&")[0].slice(0, 7) === "pattern")
                                  patternFilterHandle(
                                    {
                                      _id: data?.split("=")[1],
                                      name: data?.split("=")[1],
                                    },
                                    "remove",
                                  )
                              }}>
                              <svg
                                stroke="#000"
                                fill="#000"
                                stroke-width="0"
                                viewBox="0 0 15 15"
                                height="0.7em"
                                width="0.7em"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                  fillRule="evenodd"
                                  clip-rule="evenodd"
                                  d="M11.7816 4.03157C12.0062 3.80702 12.0062 3.44295 11.7816 3.2184C11.5571 2.99385 11.193 2.99385 10.9685 3.2184L7.50005 6.68682L4.03164 3.2184C3.80708 2.99385 3.44301 2.99385 3.21846 3.2184C2.99391 3.44295 2.99391 3.80702 3.21846 4.03157L6.68688 7.49999L3.21846 10.9684C2.99391 11.193 2.99391 11.557 3.21846 11.7816C3.44301 12.0061 3.80708 12.0061 4.03164 11.7816L7.50005 8.31316L10.9685 11.7816C11.193 12.0061 11.5571 12.0061 11.7816 11.7816C12.0062 11.557 12.0062 11.193 11.7816 10.9684L8.31322 7.49999L11.7816 4.03157Z"
                                  fill="#000"></path>
                              </svg>
                            </Button>
                          </div>
                        )
                      }
                    })}
                  </div>
                </div>
              </div>
              <InfiniteScroll
                dataLength={allProducts.length}
                next={() => setPage(page + 1)}
                hasMore={allProducts.length < totalResults}
                loader={loading && <SpinnerComponent />}>
                <div className="row">
                  {allProducts?.length === 0 && !loading ? (
                    <div className="no-results-found mt-3">
                      <h5>No matches found</h5>
                      <p>
                        Try a different search term or check our some of our
                        suggestions below.
                      </p>
                      {/* <div className="row mt-3">
                        <div className="col-xl-3 col-sm-12">
                          <div
                            className="no-category-found"
                            onClick={() => {
                              dispatch(productActions.setSearch(null))
                              categoryFilterHandle(
                                {
                                  _id: "638b474aa1ecae697bce7bcf",
                                  name: "Mesh",
                                },
                                "add",
                              )
                            }}>
                            <h5>Mesh Fabric </h5>
                            <svg
                              className="right-arrow"
                              stroke="#000"
                              fill="#000"
                              stroke-width="0.5"
                              viewBox="0 0 16 16"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                fillRule="evenodd"
                                d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"></path>
                            </svg>
                          </div>
                        </div>
                        <div className="col-xl-3 col-sm-12">
                          <div
                            className="no-category-found"
                            onClick={() => {
                              dispatch(productActions.setSearch(null))
                              categoryFilterHandle(
                                {
                                  _id: "638b4725a1ecae697bce7bc0",
                                  name: "Lace",
                                },
                                "add",
                              )
                            }}>
                            <h5>Lace Fabric </h5>
                            <svg
                              className="right-arrow"
                              stroke="#000"
                              fill="#000"
                              stroke-width="0.5"
                              viewBox="0 0 16 16"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                fillRule="evenodd"
                                d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"></path>
                            </svg>
                          </div>
                        </div>
                        <div className="col-xl-3 col-sm-12">
                          <div
                            className="no-category-found"
                            onClick={() => {
                              dispatch(productActions.setSearch(null))
                              categoryFilterHandle(
                                {
                                  _id: "638b473aa1ecae697bce7bca",
                                  name: "Lurex",
                                },
                                "add",
                              )
                            }}>
                            <h5>Lurex Fabric </h5>
                            <svg
                              className="right-arrow"
                              stroke="#000"
                              fill="#000"
                              stroke-width="0.5"
                              viewBox="0 0 16 16"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                fillRule="evenodd"
                                d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"></path>
                            </svg>
                          </div>
                        </div>
                        <div className="col-xl-3 col-sm-12">
                          <div
                            className="no-category-found"
                            onClick={() => {
                              dispatch(productActions.setSearch(null))
                              categoryFilterHandle(
                                {
                                  _id: "638b4758a1ecae697bce7bd9",
                                  name: "Vinyl",
                                },
                                "add",
                              )
                            }}>
                            <h5>Vinyl Fabric </h5>
                            <svg
                              className="right-arrow"
                              stroke="#000"
                              fill="#000"
                              stroke-width="0.5"
                              viewBox="0 0 16 16"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                fillRule="evenodd"
                                d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"></path>
                            </svg>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-3" id="no-margin-mobile">
                        <div className="col-xl-3 col-sm-12">
                          <div
                            className="no-category-found"
                            onClick={() => {
                              dispatch(productActions.setSearch(null))
                              categoryFilterHandle(
                                {
                                  _id: "638b4758a1ecae697bce7bd9",
                                  name: "Lame",
                                },
                                "add",
                              )
                            }}>
                            <h5>Lame Fabric </h5>
                            <svg
                              className="right-arrow"
                              stroke="#000"
                              fill="#000"
                              stroke-width="0.5"
                              viewBox="0 0 16 16"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                fillRule="evenodd"
                                d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"></path>
                            </svg>
                          </div>
                        </div>
                        <div className="col-xl-3 col-sm-12">
                          <div
                            className="no-category-found"
                            onClick={() => {
                              dispatch(productActions.setSearch(null))
                              categoryFilterHandle(
                                {
                                  _id: "638b4752a1ecae697bce7bd4",
                                  name: "Velvet",
                                },
                                "add",
                              )
                            }}>
                            <h5>Velvet Sequins Fabric </h5>
                            <svg
                              className="right-arrow"
                              stroke="#000"
                              fill="#000"
                              stroke-width="0.5"
                              viewBox="0 0 16 16"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                fillRule="evenodd"
                                d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"></path>
                            </svg>
                          </div>
                        </div>
                        <div className="col-xl-3 col-sm-12">
                          <div
                            className="no-category-found"
                            onClick={() => {
                              dispatch(productActions.setSearch(null))
                              categoryFilterHandle(
                                {
                                  _id: "638b4711a1ecae697bce7bbb",
                                  name: "Chiffon",
                                },
                                "add",
                              )
                            }}>
                            <h5>Chiffon Fabric </h5>
                            <svg
                              className="right-arrow"
                              stroke="#000"
                              fill="#000"
                              stroke-width="0.5"
                              viewBox="0 0 16 16"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                fillRule="evenodd"
                                d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"></path>
                            </svg>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  ) : (
                    allProducts?.map((data, i) => {
                      return (
                        <div className="col-lg-4 col-sm-12 mb-25">
                          <div
                            className="h-100"
                            onClick={() => handleProductClick(data)}>
                            <ProductBox
                              key={data?._id}
                              id={data?._id}
                              quantity={data?.quantity}
                              // colorId={
                              //   data?.color[colorIdFilterFunction(data?.color, i)]
                              // }
                              // colorId={data?.productImage?.color}
                              img={data?.productImage[0]}
                              name={data?.name}
                              price={data?.amount}
                              specialAmount={data?.specialAmount}
                              subDescriptions={data?.subDescriptions}
                              composition={data?.content}
                              wishList={data?.WishlistProduct}
                            />
                          </div>
                        </div>
                      )
                    })
                  )}
                </div>
              </InfiniteScroll>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductList
